import React, { Component } from 'react'
import { withStyles, Card, CardHeader, Avatar, Typography, CardContent, Grid, TextField, FormControl, InputLabel, Select, OutlinedInput, MenuItem, CardActions, Button } from '@material-ui/core';
import Add from '@material-ui/icons/Add'
import { DeleteData, PostData } from '../../api/service';
import FileUpload from '../AddNew/FileUpload';
import Snack from '../Snackbar/Snack';
import ViewCollegeGallery from '../ViewAll/ViewCollegeGallery';

const styles = theme => ({
    textField: {
        width: '100%'
    },
    formControl: {
        width: '100%',
        marginTop: theme.spacing.unit * 2
    }
})

class FacultyGallery extends Component {

    state = {
        date: '',
        type: 'FACULTY',
        dept: this.props.employee_id,
        caption: '',
        upload_info: [],
        dept_code:this.props.employee_id,
        open: false,
        message: '',
        isLoading:true,
        edit:false,
    }

    __getInitialState() {
        this.setState({
            date: '',
            type: 'FACULTY',
            dept: this.props.employee_id,
            caption: '',
            upload_info: [],
            dept_code: this.props.employee_id,
        },() => {
            this.setState({
                loadFile: true
            })
        })
    }


    componentDidMount() {
        PostData(`/getgallery`,this.state)
        .then((resp) => {
            //console.log(resp);
            this.setState({
                table_data: resp,
                isLoading: false
            })
        })
        console.log(this.state)
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.__loadType()
        }
    }



    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        // console.log(this.state);
    }


    onSetFile = (data) => {
        console.log(data)
        this.setState({
            upload_info: data
        })
        console.log(this.state);
    }

    onSubmit = (e) => {
        e.preventDefault();

        let data = {
            ...this.state
        }

        console.log(data);


        PostData(`/admin/addgallery`, data)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    open: true,
                    message: resp
                })
            }).then(() => {
                this.__getInitialState();
            })


    }

    actionClick = (el) => {
        this.setState({
           ...el,
           edit: true
       })
       console.log(this.state);
       console.log(el);
    }

     onDeleteConfirm = (id) => {
        DeleteData(`/${this.props.apikey}/${id}/deletegallery`)
            .then((resp) => {
                this.setState({
                    message: resp,
                    open: true
                }, () => this.__getDeptData())
            })
    }


    cancelEdit = () => {
        this.setState({
            date: '',
            type: 'FACULTY',
            dept: this.props.employee_id,
            caption: '',
            upload_info: [],
            dept_code:this.props.employee_id,
            open: false,
            message: '',
            isLoading:true,
            edit:false,
        }, () => {
            this.setState({
                loadFile: true
            })
        })
    }

    render() {
        let { classes } = this.props;

        let i = <Grid key={0} item lg={4} sm={12}>
            <TextField
                label="Opening Date"
                className={classes.textField}
                type="date"
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                    shrink: true
                }}

                name="opening_date"
                value={this.state.opening_date}
                onChange={this.handleChange}
            />
        </Grid>;

        let j = <Grid key={1} item lg={4} sm={12}>
            <TextField
                label="Closing Date"
                className={classes.textField}
                type="date"
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                    shrink: true
                }}

                name="closing_date"
                value={this.state.closing_date}
                onChange={this.handleChange}
            />
        </Grid>
        return (
            <div>
                {this.state.open ?
                    <Snack
                        open={this.state.open}
                        handleClose={() => {
                            this.setState({
                                open: false,
                                message: ''
                            })
                        }}
                        message={this.state.message}
                    />

                    : null}
                <form onSubmit={this.onSubmit}>
                    <Card>
                        <CardHeader
                            avatar={
                                <Avatar>
                                    <Add />
                                </Avatar>
                            }
                            title={
                                <Typography
                                    variant="h6"
                                >
                                    Add New {this.props.title}
                                </Typography>
                            }
                        />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item lg={4} sm={12}>
                                    <TextField
                                        label="Date"
                                        className={classes.textField}
                                        type="date"
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        required={true}
                                        name="date"
                                        value={this.state.time}
                                        onChange={this.handleChange}
                                    />
                                </Grid>

                                <Grid item lg={8} sm={12}>
                                    <TextField
                                        label="Caption"
                                        className={classes.textField}
                                        type="text"
                                        margin="normal"
                                        variant="outlined"
                                        required={true}
                                        name="caption"
                                        value={this.state.caption}
                                        onChange={this.handleChange}
                                    />
                                </Grid>

                                <Grid item lg={12} sm={12}>
                                    {this.props.code !== "" && <FileUpload
                                        dp={this.state.upload_info}
                                        apikey={this.props.apikey}
                                        type="FACULTY_GALLERY"
                                        setDp={this.onSetFile.bind(this)}
                                    />}
                                </Grid>
                            </Grid>
                        </CardContent>

                        <CardActions>
                            <Button
                                variant="outlined"
                                color="primary"
                                type="submit"
                            >
                                Submit
            </Button>
                        </CardActions>

                    </Card>
                    {!this.state.isLoading && (
                        <ViewCollegeGallery
                          table_data={this.state.table_data}
                          isLoading={this.state.isLoading}
                          actionClick={this.actionClick}
                        />
                    )
                    }
                </form>

            </div>
        )
    }
}

export default withStyles(styles)(FacultyGallery)