import React, { Component } from "react";
import {
  Typography,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Chip,
} from "@material-ui/core";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, FormControl, Card } from "react-bootstrap";
import { PostData, GetData, DeleteData, PutData } from "../../api/service";
import Snack from "../Snackbar/Snack";
import FileUpload from "../AddNew/FileUpload";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import history from "../../history";

class FacultyProjectGuideship extends Component {
  state = {
    m_type: this.props.m_type,
    employee_id: this.props.employee_id,
    email: this.props.email,
    message: "",
    open: false,
    isLoaded: false,
    name: "",
    course: "",
    institution: "",
    title: "",
    registration_no: "",
    year_from: "",
    year_to: "",

    project_guideship: [],
  };

  handleChangeInput = (name, value, index, array) => {
    let d = this.state[array];
    d[index][name] = value;
    this.setState({
      [array]: d,
    });
  };

  handleChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    //console.log(this.props);

    GetData(`/admin/${this.props.employee_id}/getsingleempolyeedataapi`).then(
      (resp) => {
        if (resp) {
          this.setState({
            ...resp,
          });
        }
        this.setState({
          isLoaded: true,
        });
      }
    );
  }

  //   componentDidUpdate(prevProps){
  //     if(this.props !== prevProps){
  //         this.setState({
  //             seminar:this.props.seminar

  //         })
  //     }
  // }

  onSubmit = (e) => {
    e.preventDefault();

    let d = this.state;

    PostData(`/admin/${this.props.employee_id}/addemployeeapi`, d).then(
      (resp) => {
        //console.log(resp)
        this.setState({
          message: resp,
          open: true,
        });
      }
    );
  };

  onAddClick = () => {
    let d = {
      name: this.state.name,
      course: this.state.course,
      institution: this.state.institution,
      title: this.state.title,
      registration_no: this.state.registration_no,
      year_from: this.state.year_from,
      year_to: this.state.year_to,
    };

    let project_guideship =
      this.state.project_guideship != null ? this.state.project_guideship : [];
    project_guideship.push(d);
    this.setState(
      {
        project_guideship,
      },
      () => {
        this.setState({
          name: "",
          course: "",
          institution: "",
          title: "",
          registration_no: "",
          year_from: "",
          year_to: "",
        });
      }
    );
  };

  onDelete = (index) => {
    let project_guideship = this.state.project_guideship;
    project_guideship.splice(index, 1);

    this.setState({
      project_guideship,
    });
  };

  render() {
    return (
      <div>
        {this.state.open ? (
          <Snack
            open={this.state.open}
            message={this.state.message}
            handleClose={() => {
              this.setState({
                open: false,
                message: "",
              });
            }}
          />
        ) : null}

        {this.state.isLoaded && (
          <form onSubmit={this.onSubmit}>
            <Typography variant="h6" gutterBottom>
              {this.props.title}
            </Typography>

            <table width="100%" className="table">
              <thead>
                <tr>
                  <td>Name of the Scholar</td>
                  <td>MPhil/Pg Course</td>
                  <td>Institution</td>
                  <td>Subject/Title of the Project</td>
                  <td>Enrollment / Registration No</td>
                  <td>Year From</td>
                  <td>Year to</td>

                  <td>Click + to Add</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      value={this.state.name}
                      onChange={this.onChange}
                      placeholder="Name"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="course"
                      value={this.state.course}
                      onChange={this.onChange}
                      placeholder="MPhil/PG Course"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="institution"
                      value={this.state.institution}
                      onChange={this.onChange}
                      placeholder="Institution"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title"
                      value={this.state.title}
                      onChange={this.onChange}
                      placeholder="Subject/Title of the Project"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="registration_no"
                      value={this.state.registration_no}
                      onChange={this.onChange}
                      placeholder="Enrollment / Registration No"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="year_from"
                      value={this.state.year_from}
                      onChange={this.onChange}
                      placeholder="Year From"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="year_to"
                      value={this.state.year_to}
                      onChange={this.onChange}
                      placeholder="Year To"
                    />
                  </td>

                 

                  <td>
                    <Button
                      variant="outlined"
                      onClick={this.onAddClick}
                      type="button"
                    >
                      +
                    </Button>
                  </td>
                </tr>
              </tbody>
              <tbody>
                {Array.isArray(this.state.project_guideship) &&
                  this.state.project_guideship.map((el, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          rows={5}
                          value={el.name}
                          onChange={(e) =>
                            this.handleChangeInput(
                              "name",
                              e.target.value,
                              index,
                              "project_guideship"
                            )
                          }
                          placeholder="Name"
                        />
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="course"
                          rows={5}
                          value={el.course}
                          onChange={(e) =>
                            this.handleChangeInput(
                              "course",
                              e.target.value,
                              index,
                              "project_guideship"
                            )
                          }
                          placeholder="Mphil/PG Course"
                        />
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="institution"
                          rows={5}
                          value={el.institution}
                          onChange={(e) =>
                            this.handleChangeInput(
                              "institution",
                              e.target.value,
                              index,
                              "project_guideship"
                            )
                          }
                          placeholder="Institution"
                        />
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="title"
                          rows={5}
                          value={el.title}
                          onChange={(e) =>
                            this.handleChangeInput(
                              "title",
                              e.target.value,
                              index,
                              "project_guideship"
                            )
                          }
                          placeholder="Subject/Title of the Project"
                        />
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="registration_no"
                          rows={5}
                          value={el.registration_no}
                          onChange={(e) =>
                            this.handleChangeInput(
                              "registration_no",
                              e.target.value,
                              index,
                              "project_guideship"
                            )
                          }
                          placeholder="Enrollment / Registration No"
                        />
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="year_from"
                          rows={5}
                          value={el.year_from}
                          onChange={(e) =>
                            this.handleChangeInput(
                              "year_from",
                              e.target.value,
                              index,
                              "project_guideship"
                            )
                          }
                          placeholder="Year From"
                        />
                      </td>


                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="year_to"
                          rows={5}
                          value={el.year_to}
                          onChange={(e) =>
                            this.handleChangeInput(
                              "year_to",
                              e.target.value,
                              index,
                              "project_guideship"
                            )
                          }
                          placeholder="Year To"
                        />
                      </td>
                      

                      <td>
                        <Delete onClick={this.onDelete.bind(this, index)} />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <br />
            <div align="right">
              <Button size="sm" variant="info" type="submit">
                Submit
              </Button>
            </div>
          </form>
        )}
      </div>
    );
  }
}

export default FacultyProjectGuideship;
