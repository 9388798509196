import React from "react";
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";

export default function QrCodeComponent({ value, name }) {
  const qrCodeRef = React.useRef(null);

  const downloadQRCode = () => {
    const qrCodeDiv = qrCodeRef.current;

    html2canvas(qrCodeDiv)
      .then((canvas) => {
        const url = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = url;
        link.download = `${name}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error converting QR code container to image:", error);
      });
  };
  return (
    <div>
      <div ref={qrCodeRef} style={{ padding: 10, backgroundColor: "white" }}>
        <QRCode
          size={256}
          style={{
            height: "auto",
            maxWidth: "300px",
            width: "300px",
          }}
          value={value}
          viewBox={`0 0 1000 1000`}
        />
        <div style={{display: "flex", flexDirection: "row"}}>
          <div style={{flexBasis: "40%"}}>
            <img
              src={require(`./../../assets/dc_qr.png`)}
              style={{ width: 100 }}
            />
          </div>
          <div style={{flexBasis: "60%", verticalAlign: "middle", marginTop: 12}}>
            <div style={{fontSize: 24, fontWeight: 700, whiteSpace: "nowrap"}}>DISPUR COLLEGE</div>
            <img src={require(`./../../assets/dispur_college_qr.png`)} style={{width: 200}} />
          </div>
        </div>
      </div>
      <div style={{textAlign: "center"}}>
        <a style={{ cursor: "pointer", color: "blue" }} onClick={downloadQRCode}>
          <u>Download QR</u>
        </a>
      </div>
    </div>
  );
}
