import React, { Component } from "react";
import {
  Typography,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Chip,
} from "@material-ui/core";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, FormControl, Card } from "react-bootstrap";
import { PostData, GetData, DeleteData, PutData } from "../../api/service";
import Snack from "../Snackbar/Snack";
import FileUpload from "../AddNew/FileUpload";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import QRCode from "react-qr-code";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import QrCodeComponent from "./QrCodeComponent";

class DepartmentWallMagazine extends Component {
  state = {
    id: "",
    edit: false,
    description: "",
    title: "",
    upload_info: [],
    data: [],
    isDataLoaded: false,
    dept_code: this.props.dept_code,
    message: "",
    open: false,
    loadFile: true,
  };

  handleChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  __getDeptData = () => {
    GetData(
      `/${this.props.dept_code}/${this.props.type}/getdepartmentfilebytype`
    ).then((resp) => {
      //console.log(resp)
      this.setState({
        data: resp,
        isDataLoaded: true,
      });
    });
  };

  componentDidMount() {
    this.__getDeptData();
  }

  onSubmit = (e) => {
    e.preventDefault();

    let d = {
      id: this.state.id,
      title: this.state.title,
      description: this.state.description,
      upload_info: this.state.upload_info,
      type: this.props.type,
      dept_code: this.props.dept_code,
    };

    if (this.state.edit) {
      PutData(`/admin/${this.state.id}/editdepartmentfile`, d).then((resp) => {
        //console.log(resp)
        this.setState(
          {
            message: resp,
            open: true,
          },
          () => this.__getDeptData()
        );
        this.cancelEdit();
      });
    } else {
      PostData(`/admin/adddepartmentfile`, d).then((resp) => {
        //console.log(resp)
        this.setState(
          {
            message: resp,
            open: true,
          },
          () => this.__getDeptData()
        );
      });
    }
  };

  onDeleteClick = (el) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: this.onDeleteConfirm.bind(this, el.id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  onDeleteConfirm = (id) => {
    DeleteData(`/${this.props.apikey}/${id}/deletedepartmentfile`).then(
      (resp) => {
        this.setState(
          {
            message: resp,
            open: true,
          },
          () => this.__getDeptData()
        );
      }
    );
  };

  onEditClick = (el) => {
    this.setState(
      {
        ...el,
        edit: true,
        loadFile: false,
      },
      () => {
        this.setState({
          loadFile: true,
        });
      }
    );
  };

  cancelEdit = () => {
    this.setState(
      {
        id: "",
        title: "",
        description: "",
        upload_info: [],
        edit: false,
        loadFile: false,
      },
      () => {
        this.setState({
          loadFile: true,
        });
      }
    );
  };

  getSinglePath = (uploadInfo) => {
    if (Array.isArray(uploadInfo) && uploadInfo.length > 0) {
      return uploadInfo[0].url;
    } else {
      return false;
    }
  };

  render() {
    return (
      <div>
        {this.state.open ? (
          <Snack
            open={this.state.open}
            message={this.state.message}
            handleClose={() => {
              this.setState({
                open: false,
                message: "",
              });
            }}
          />
        ) : null}

        <form onSubmit={this.onSubmit}>
          <Typography variant="h6" gutterBottom>
            {this.props.type}
          </Typography>

          <Card>
            <Card.Body>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <label>File name or title of the file</label>
                  <FormControl
                    as="input"
                    name="title"
                    required
                    onChange={this.onChange}
                    value={this.state.title}
                    placeholder="Title for the file"
                  />

                  <label>Description</label>
                  {this.state.isDataLoaded && (
                    <CKEditor
                      editor={ClassicEditor}
                      data={this.state.description}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log("Editor is ready to use!", editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        this.handleChange(`description`, data);
                        console.log({ event, editor, data });
                      }}
                      onBlur={(event, editor) => {
                        console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        console.log("Focus.", editor);
                      }}
                    />
                  )}
                  {/* <ReactQuill
                                        placeholder="Type something here"
                                        value={this.state.description}
                                        onChange={this.handleChange.bind(this, 'description')}
                                    /> */}
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <label>Upload the file (JPEG only)</label>
                  {this.state.loadFile ? (
                    <FileUpload
                      dp={this.state.upload_info}
                      type={`WALL_MAGAZINE`}
                      setDp={this.handleChange.bind(this, "upload_info")}
                    />
                  ) : null}
                </Grid>
              </Grid>

              {this.state.edit ? (
                <div align="right">
                  <Button size="sm" variant="danger" type="submit">
                    Update
                  </Button>
                  &nbsp;
                  <Button
                    size="sm"
                    variant="danger"
                    type="button"
                    onClick={this.cancelEdit}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                <div align="right">
                  <Button size="sm" variant="info" type="submit">
                    Submit
                  </Button>
                </div>
              )}
            </Card.Body>
          </Card>

          <br />

          <Card>
            <Card.Body>
              {this.state.isDataLoaded ? (
                <div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell width="30%">Title</TableCell>
                        <TableCell width="30%">Description</TableCell>
                        <TableCell wodth="20%">Files</TableCell>
                        <TableCell wodth="20%">QR Code</TableCell>
                        <TableCell>Edit</TableCell>
                        <TableCell>Delete</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.data.map((el, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{el.title}</TableCell>
                          <TableCell
                            dangerouslySetInnerHTML={{ __html: el.description }}
                          ></TableCell>
                          <TableCell>
                            {Array.isArray(el.upload_info) &&
                              el.upload_info.map((el1, index1) => (
                                <Chip key={index1} label={el1.path} />
                              ))}
                          </TableCell>
                          <TableCell>
                            {this.getSinglePath(el.upload_info) && (
                              <QrCodeComponent
                                value={this.getSinglePath(el.upload_info)}
                                name={`${el.id}_${el.title}`}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            <Edit onClick={this.onEditClick.bind(this, el)} />
                          </TableCell>
                          <TableCell>
                            <Delete
                              onClick={this.onDeleteClick.bind(this, el)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              ) : (
                <Typography>Loading . . .</Typography>
              )}
            </Card.Body>
          </Card>
        </form>
      </div>
    );
  }
}

export default DepartmentWallMagazine;
