import React, { Component } from 'react';
import { Switch, Link, Route } from 'react-router-dom'
import withAuth from '../auth_components/js/withAuth';
import Navbar from '../Components/Navbar';
import NssHomeLanding from '../Components/Ncc/NssHomeLanding';

class NssContainer extends Component {
    constructor(props) {
        super(props);

        let user = JSON.parse(localStorage.getItem('user'));


        this.state = {
            apikey: user.user.apikey,
            username: user.user.email,
            name: user.user.name,
            role: user.user.name
        }

    }

    render() {
        return (
            <div>
                <Navbar history={this.props.history} />
                <div className="main-body">


                    <Switch>
                        <Route exact path="/nss" render={(props) =>
                            <NssHomeLanding
                                {...props}
                                history={this.props.history}
                                title="Notification"
                                code="NOTICE"
                                apikey={this.state.apikey}
                                username={this.state.username}
                            />} />


                        





                    </Switch>



                </div>
            </div>
        )
    }
}

export default withAuth(NssContainer);