import React, { Component } from "react";
import {
  Typography,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Chip,
} from "@material-ui/core";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, FormControl, Card } from "react-bootstrap";
import { PostData, GetData, DeleteData, PutData } from "../../api/service";
import Snack from "../Snackbar/Snack";
import FileUpload from "../AddNew/FileUpload";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import history from "../../history";

class FacultyResearchGuideship extends Component {
  state = {
    m_type: this.props.m_type,
    employee_id: this.props.employee_id,
    email: this.props.email,
    message: "",
    open: false,
    isLoaded: false,
    name: "",
    date_of_registration: "",
    topic: "",
    status: "",

    guideship: [],
  };

  handleChangeInput = (name, value, index, array) => {
    let d = this.state[array];
    d[index][name] = value;
    this.setState({
      [array]: d,
    });
  };

  handleChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    //console.log(this.props);

    GetData(`/admin/${this.props.employee_id}/getsingleempolyeedataapi`).then(
      (resp) => {
        if (resp) {
          this.setState({
            ...resp,
          });
        }
        this.setState({
          isLoaded: true,
        });
      }
    );
  }

  //   componentDidUpdate(prevProps){
  //     if(this.props !== prevProps){
  //         this.setState({
  //             seminar:this.props.seminar

  //         })
  //     }
  // }

  onSubmit = (e) => {
    e.preventDefault();

    let d = this.state;

    PostData(`/admin/${this.props.employee_id}/addemployeeapi`, d).then(
      (resp) => {
        //console.log(resp)
        this.setState({
          message: resp,
          open: true,
        });
      }
    );
  };

  onAddClick = () => {
    let d = {
      name: this.state.name,
      date_of_registration: this.state.date_of_registration,
      topic: this.state.topic,
      status: this.state.status,
    };

    let guideship = this.state.guideship != null ? this.state.guideship : [];
    guideship.push(d);
    this.setState(
      {
        guideship,
      },
      () => {
        this.setState({
          name: "",
          date_of_registration: "",
          topic: "",
          status: "",
        });
      }
    );
  };

  onDelete = (index) => {
    let guideship = this.state.guideship;
    guideship.splice(index, 1);

    this.setState({
      guideship,
    });
  };

  render() {
    return (
      <div>
        {this.state.open ? (
          <Snack
            open={this.state.open}
            message={this.state.message}
            handleClose={() => {
              this.setState({
                open: false,
                message: "",
              });
            }}
          />
        ) : null}

        {this.state.isLoaded && (
          <form onSubmit={this.onSubmit}>
            <Typography variant="h6" gutterBottom>
              {this.props.title}
            </Typography>

            <table width="100%" className="table">
              <thead>
                <tr>
                  <td>Name of the Scholars</td>
                  <td>Date of Reg</td>
                  <td>Topic of Research</td>
                  <td>Status</td>

                  <td>Click + to Add</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      value={this.state.name}
                      onChange={this.onChange}
                      placeholder="Name"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="date_of_registration"
                      value={this.state.date_of_registration}
                      onChange={this.onChange}
                      placeholder="Date of Registration"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="topic"
                      value={this.state.topic}
                      onChange={this.onChange}
                      placeholder="Topic of Research"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="status"
                      value={this.state.status}
                      onChange={this.onChange}
                      placeholder="Status"
                    />
                  </td>

                  <td>
                    <Button
                      variant="outlined"
                      onClick={this.onAddClick}
                      type="button"
                    >
                      +
                    </Button>
                  </td>
                </tr>
              </tbody>
              <tbody>
                {Array.isArray(this.state.guideship) &&
                  this.state.guideship.map((el, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="date_year"
                          rows={5}
                          value={el.name}
                          onChange={(e) =>
                            this.handleChangeInput(
                              "name",
                              e.target.value,
                              index,
                              "guideship"
                            )
                          }
                          placeholder="Name"
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="date_of_registration"
                          rows={5}
                          value={el.name}
                          onChange={(e) =>
                            this.handleChangeInput(
                              "date_of_registration",
                              e.target.value,
                              index,
                              "guideship"
                            )
                          }
                          placeholder="Date of Reg	"
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="date_of_registration"
                          rows={5}
                          value={el.topic}
                          onChange={(e) =>
                            this.handleChangeInput(
                              "topic",
                              e.target.value,
                              index,
                              "guideship"
                            )
                          }
                          placeholder="Topic of Research	"
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="status"
                          rows={5}
                          value={el.status}
                          onChange={(e) =>
                            this.handleChangeInput(
                              "status",
                              e.target.value,
                              index,
                              "guideship"
                            )
                          }
                          placeholder="Status"
                        />
                      </td>

                      <td>
                        <Delete onClick={this.onDelete.bind(this, index)} />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <br />
            <div align="right">
              <Button size="sm" variant="info" type="submit">
                Submit
              </Button>
            </div>
          </form>
        )}
      </div>
    );
  }
}

export default FacultyResearchGuideship;
