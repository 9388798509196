import React, { Component } from "react";
import {
  Typography,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Chip,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, FormControl, Card } from "react-bootstrap";
import { PostData, GetData, DeleteData, PutData } from "../../api/service";
import Snack from "../Snackbar/Snack";
import FileUpload from "../AddNew/FileUpload";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import history from "../../history";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

class FacultyPublications extends Component {
  state = {
    m_type: this.props.m_type,
    employee_id: this.props.employee_id,
    email: this.props.email,
    message: "",
    open: false,
    isLoaded: false,
    date_year: "",
    title_paper: "",
    title_book: "",
    published_by: "",
    isbn_no: "",
    issn_no:'',
    other_info:'',
    research_paper: [],
    reserarch_paper_panel:false,
    journal: [],
    journal_panel:false,
    text_book: [],
    text_book_panel:false,
    magazine: [],
    magazine_panel:false,
    popular_book: [],
    popular_book_panel:false,
    news_paper:[],
    news_paper_panel:false,
    other_publication:[],
    other_publication_panel:false,
    econtent:[],
    econtent_panel:false
  };

  clearData = ()=>{
      this.setState({
        date_year: "",
        title_paper: "",
        title_book: "",
        published_by: "",
        isbn_no: "",
        issn_no:'',
        other_info:'', 
      })
  }

  onPanelClick = (type,flag)=>{
        this.clearData();
        this.setState({
        reserarch_paper_panel:type=='reserarch_paper_panel'?flag:false,
        journal_panel:type=='journal_panel'?flag:false,
        text_book_panel:type=='text_book_panel'?flag:false,
        magazine_panel:type=='magazine_panel'?flag:false,
        popular_book_panel:type=='popular_book_panel'?flag:false,
        news_paper_panel:type=='news_paper_panel'?flag:false,
        other_publication_panel:type=='other_publication_panel'?flag:false,
        econtent_panel:type=='econtent_panel'?flag:false

        })
    
  }

  handleChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeInput = (name, value, index, array) => {

    let d = this.state[array];
    d[index][name] = value
    this.setState({
      [array]: d
    });
  };

  componentDidMount() {
    //console.log(this.props);

    GetData(`/admin/${this.props.employee_id}/getsingleempolyeedataapi`).then(
      (resp) => {
        if (resp) {
          this.setState({
            ...resp,

            
          });
        }
        this.setState({
          isLoaded: true
        })
      }
    );
  }

  //   componentDidUpdate(prevProps){
  //     if(this.props !== prevProps){
  //         this.setState({
  //             seminar:this.props.seminar

  //         })
  //     }
  // }

  onSubmit = (e) => {
    e.preventDefault();

    let d = this.state;

    PostData(`/admin/${this.props.employee_id}/addemployeeapi`, d).then(
      (resp) => {
        //console.log(resp)
        this.setState({
          message: resp,
          open: true,
        });
      }
    );
  };

  onAddClick = (type) => {
    if (type == "research_paper") {
      let d = {
        date_year: this.state.date_year,
        title_paper: this.state.title_paper,
        title_book: this.state.title_book,
        published_by: this.state.published_by,
        isbn_no: this.state.isbn_no,
        volume: this.state.volume,
        page_nos: this.state.page_nos,
      };

      let research_paper = this.state.research_paper != null ? this.state.research_paper : [];
      research_paper.push(d);
      this.setState(
        {
          research_paper,
        },
        () => {
          this.setState({
            date_year: "",
            title_paper: "",
            title_book: "",
            published_by: "",
            isbn_no: "",
            volume: "",
            page_nos: "",
          });
        }
      );
    }

    else if (type == "journal") {
        let d = {
          date_year: this.state.date_year,
          title_paper: this.state.title_paper,
          title_book: this.state.title_book,
          published_by: this.state.published_by,
          issn_no: this.state.issn_no,
        };
  
        let journal = this.state.journal != null ? this.state.journal : [];
        journal.push(d);
        this.setState(
          {
            journal,
          },
          () => {
            this.setState({
              date_year: "",
              title_paper: "",
              title_book: "",
              published_by: "",
              issn_no: "",
            });
          }
        );
      }

      else  if (type == "text_book") {
        let d = {
          date_year: this.state.date_year,
          title_book: this.state.title_book,
          published_by: this.state.published_by,
          isbn_no: this.state.isbn_no,
          volume: this.state.volume,
          page_nos: this.state.page_nos,
          edition: this.state.edition,
          name_of_authors: this.state.name_of_authors,
        };
  
        let text_book = this.state.text_book != null ? this.state.text_book : [];
        text_book.push(d);
        this.setState(
          {
            text_book,
          },
          () => {
            this.setState({
              date_year: "",
              title_book: "",
              published_by: "",
              isbn_no: "",
              volume: "",
              page_nos: "",
              edition: "",
              name_of_authors: "",
            });
          }
        );
      }
      else  if (type == "magazine") {
        let d = {
          date_year: this.state.date_year,
          title_book: this.state.title_book,
          published_by: this.state.published_by,
          isbn_no: this.state.isbn_no,
          other_info: this.state.other_info,
        };
  
        let magazine = this.state.magazine != null ? this.state.magazine : [];
        magazine.push(d);
        this.setState(
          {
            magazine,
          },
          () => {
            this.setState({
              date_year: "",
              title_book: "",
              published_by: "",
              isbn_no: "",
              other_info:''
            });
          }
        );
      }

      else if (type == "popular_book") {
        let d = {
          date_year: this.state.date_year,
          title_paper: this.state.title_paper,
          title_book: this.state.title_book,
          published_by: this.state.published_by,
          isbn_no: this.state.isbn_no,
          volume: this.state.volume,
          page_nos: this.state.page_nos,
        };
  
        let popular_book = this.state.popular_book != null ? this.state.popular_book : [];
        popular_book.push(d);
        this.setState(
          {
            popular_book,
          },
          () => {
            this.setState({
              date_year: "",
              title_paper: "",
              title_book: "",
              published_by: "",
              isbn_no: "",
              volume: "",
              page_nos: "",
            });
          }
        );
      }

      else if (type == "news_paper") {
        let d = {
          date_year: this.state.date_year,
          title_paper: this.state.title_paper,
          title_book: this.state.title_book,
        };
  
        let news_paper = this.state.news_paper != null ? this.state.news_paper : [];
        news_paper.push(d);
        this.setState(
          {
            news_paper,
          },
          () => {
            this.setState({
              date_year: "",
              title_paper: "",
              title_book: "",
            });
          }
        );
      }

      else if (type == "other_publication") {
        let d = {
          date_year: this.state.date_year,
          title_book: this.state.title_book,
          published_by: this.state.published_by,
          isbn_no: this.state.isbn_no,
          other_info: this.state.other_info,
          volume: this.state.volume,
          page_nos: this.state.page_nos,
        };
  
        let other_publication = this.state.other_publication != null ? this.state.other_publication : [];
        other_publication.push(d);
        this.setState(
          {
            other_publication,
          },
          () => {
            this.setState({
              date_year: "",
              title_book: "",
              published_by: "",
              isbn_no: "",
              other_info:'',
              volume:'',
              page_nos:'',
            });
          }
        );
      }

      else if (type == "econtent") {
        let d = {
          date_year: this.state.date_year,
          title_paper: this.state.title_paper,
          title_book: this.state.title_book,
          published_by: this.state.published_by,
          other_info: this.state.other_info,
        };
  
        let econtent = this.state.econtent != null ? this.state.econtent : [];
        econtent.push(d);
        this.setState(
          {
            econtent,
          },
          () => {
            this.setState({
              date_year: "",
              title_paper:'',
              title_book: "",
              published_by: "",
             
              other_info:''
            });
          }
        );
      }
  };

  onDelete = (index, type) => {
    if (type == "research_paper") {
      let research_paper = this.state.research_paper;
      research_paper.splice(index, 1);

      this.setState({
        research_paper,
      });
    }

    else if (type == "journal") {
        let journal = this.state.journal;
        journal.splice(index, 1);
  
        this.setState({
            journal,
        });
      }

      else if (type == "text_book") {
        let text_book = this.state.text_book;
        text_book.splice(index, 1);
  
        this.setState({
            text_book,
        });
      }

      else if (type == "magazine") {
        let magazine = this.state.magazine;
        magazine.splice(index, 1);
  
        this.setState({
            magazine,
        });
      }

      else if (type == "popular_book") {
        let popular_book = this.state.popular_book;
        popular_book.splice(index, 1);
  
        this.setState({
            popular_book,
        });
      }

      else if (type == "news_paper") {
        let news_paper = this.state.news_paper;
        news_paper.splice(index, 1);
  
        this.setState({
            news_paper,
        });
      }

      else if (type == "other_publication") {
        let other_publication = this.state.other_publication;
        other_publication.splice(index, 1);
  
        this.setState({
            other_publication,
        });
      }

      else if (type == "econtent") {
        let econtent = this.state.econtent;
        econtent.splice(index, 1);
  
        this.setState({
            econtent,
        });
      }
  };

  render() {
    return (
      <div>
        {this.state.open ? (
          <Snack
            open={this.state.open}
            message={this.state.message}
            handleClose={() => {
              this.setState({
                open: false,
                message: "",
              });
            }}
          />
        ) : null}

        {this.state.isLoaded && (
          <form onSubmit={this.onSubmit}>
            <Typography variant="h6" gutterBottom>
              {this.props.title}
            </Typography>

            <ExpansionPanel expanded={this.state.reserarch_paper_panel} onChange={() => this.onPanelClick('reserarch_paper_panel',!this.state.reserarch_paper_panel)}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography>Click here to Add Research Publications</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>

            <table width="100%" className="table">
              <thead>
                <tr>
                  <td colSpan={6} align="center">
                    <Typography>Research Publications</Typography>
                  </td>
                </tr>
                <tr>
                  

                  <td>Title of the paper</td>

                  <td>Title of the Book/Journal</td>
                  <td>Published By</td>
                  <td>ISBN/ISSN</td>
                  <td>Volume</td>
                  <td>Page Nos</td>
                  <td>Year</td>

                  <td>Click + to Add</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title_paper"
                      value={this.state.title_paper}
                      onChange={this.onChange}
                      placeholder="Title of the Paper"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title_book"
                      value={this.state.title_book}
                      onChange={this.onChange}
                      placeholder="Title of the Book/Journal"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="published_by"
                      value={this.state.published_by}
                      onChange={this.onChange}
                      placeholder="Published By"
                    />
                  </td>

                  

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="isbn_no"
                      value={this.state.isbn_no}
                      onChange={this.onChange}
                      placeholder="ISBN"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="volume"
                      value={this.state.volume}
                      onChange={this.onChange}
                      placeholder="Volume"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="page_nos"
                      value={this.state.page_nos}
                      onChange={this.onChange}
                      placeholder="Page Nos"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="date_year"
                      value={this.state.date_year}
                      onChange={this.onChange}
                      placeholder="Date / Year"
                    />
                  </td>

                  <td>
                    <Button
                      variant="outlined"
                      onClick={this.onAddClick.bind(this, "research_paper")}
                      type="button"
                    >
                      +
                    </Button>
                  </td>
                </tr>
              </tbody>
              <tbody>
                {Array.isArray(this.state.research_paper) && this.state.research_paper.map((el, index) => (
                  <tr key={index}>
                    
                    <td>
                    <textarea
                      className="form-control"
                      type="text"
                      name="title_paper"
                      rows={5}
                      value={el.title_paper}
                      onChange={e => this.handleChangeInput('title_paper',e.target.value,index,'research_paper')}
                      placeholder="Title of the Paper"
                    /></td>
                    <td>
                    <textarea
                      className="form-control"
                      type="text"
                      name="title_paper"
                      rows={5}
                      value={el.title_book}
                      onChange={e => this.handleChangeInput('title_book',e.target.value,index,'research_paper')}
                      placeholder="Title of the Book/Journal	"
                    />
                    </td>
                    <td>
                    <textarea
                      className="form-control"
                      type="text"
                      name="published_by"
                      rows={5}
                      value={el.published_by}
                      onChange={e => this.handleChangeInput('published_by',e.target.value,index,'research_paper')}
                      placeholder="Published By"
                    /></td>
                    <td>
                    <input
                      className="form-control"
                      type="text"
                      name="isbn_no"
                      
                      value={el.isbn_no}
                      onChange={e => this.handleChangeInput('isbn_no',e.target.value,index,'research_paper')}
                      placeholder="ISBN/ISSN	"
                    />
                    </td>
                    <td>
                    <input
                      className="form-control"
                      type="text"
                      name="volume"
                      
                      value={el.volume}
                      onChange={e => this.handleChangeInput('volume',e.target.value,index,'research_paper')}
                      placeholder="Volume"
                    /></td>
                    <td>
                    <input
                      className="form-control"
                      type="text"
                      name="page_nos"
                      
                      value={el.page_nos}
                      onChange={e => this.handleChangeInput('page_nos',e.target.value,index,'research_paper')}
                      placeholder="Page Nos"
                    />
                    </td>
                    <td>
                    <input
                      className="form-control"
                      type="text"
                      name="date_year"
                      
                      value={el.date_year}
                      onChange={e => this.handleChangeInput('date_year',e.target.value,index,'research_paper')}
                      placeholder="Year"
                    /></td>

                    <td>
                      <Delete
                        onClick={this.onDelete.bind(
                          this,
                          index,
                          "research_paper"
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            </ExpansionPanelDetails>
            </ExpansionPanel>
            <br />

            {/* <ExpansionPanel expanded={this.state.journal_panel} onChange={() => this.onPanelClick('journal_panel',!this.state.journal_panel)}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography>Click here to Add Seminar/Research Paper in Journals</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>

            <table width="100%" className="table">
              <thead>
                <tr>
                  <td colSpan={6} align="center">
                    <Typography>Seminar/Research Paper in Journals</Typography>
                  </td>
                </tr>
                <tr>
                  <td>Date / Year</td>

                  <td>Title of the paper</td>

                  <td>Title of the Journal</td>
                  <td>Published By</td>
                  <td>ISSN</td>

                  <td>Click + to Add</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="date_year"
                      value={this.state.date_year}
                      onChange={this.onChange}
                      placeholder="Date / Year"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title_paper"
                      value={this.state.title_paper}
                      onChange={this.onChange}
                      placeholder="Title of the Paper"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title_book"
                      value={this.state.title_book}
                      onChange={this.onChange}
                      placeholder="Title of the Journal"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="published_by"
                      value={this.state.published_by}
                      onChange={this.onChange}
                      placeholder="Published By"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="issn_no"
                      value={this.state.issn_no}
                      onChange={this.onChange}
                      placeholder="ISSN"
                    />
                  </td>

                  <td>
                    <Button
                      variant="outlined"
                      onClick={this.onAddClick.bind(this, "journal")}
                      type="button"
                    >
                      +
                    </Button>
                  </td>
                </tr>
              </tbody>
              <tbody>
                {Array.isArray(this.state.journal) && this.state.journal.map((el, index) => (
                  <tr key={index}>
                    <td>{index+1}. {el.date_year}</td>
                    <td>{el.title_paper}</td>
                    <td>{el.title_book}</td>
                    <td>{el.published_by}</td>
                    <td>{el.issn_no}</td>

                    <td>
                      <Delete
                        onClick={this.onDelete.bind(
                          this,
                          index,
                          "journal"
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </ExpansionPanelDetails>
            </ExpansionPanel> */}
            

        
            <ExpansionPanel expanded={this.state.text_book_panel} onChange={() => this.onPanelClick('text_book_panel',!this.state.text_book_panel)}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography>Click here to Add Text Book/Books</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
            <table width="100%" className="table">
              <thead>
                <tr>
                  <td colSpan={5} align="center">
                    <Typography>Text Book/Book</Typography>
                  </td>
                </tr>
                <tr>
                 

                 

                  <td>Name of the Book/Text Book</td>
                  <td>Published By</td>
                  <td>ISBN</td>
                  <td>Volume</td>
                  {/* <td>Page Nos</td> */}
                  <td>Edition</td>
                  <td>Name of the Authors</td>
                  <td>Year</td>

                  <td>Click + to Add</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                 
                 

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title_book"
                      value={this.state.title_book}
                      onChange={this.onChange}
                      placeholder="Title of the Book"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="published_by"
                      value={this.state.published_by}
                      onChange={this.onChange}
                      placeholder="Published By"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="isbn_no"
                      value={this.state.isbn_no}
                      onChange={this.onChange}
                      placeholder="ISBN"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="volume"
                      value={this.state.volume}
                      onChange={this.onChange}
                      placeholder="Volume"
                    />
                  </td>

                  {/* <td>
                    <input
                      className="form-control"
                      type="text"
                      name="page_nos"
                      value={this.state.page_nos}
                      onChange={this.onChange}
                      placeholder="Page Nos"
                    />
                  </td> */}

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="edition"
                      value={this.state.edition}
                      onChange={this.onChange}
                      placeholder="Edition"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="name_of_authors"
                      value={this.state.name_of_authors}
                      onChange={this.onChange}
                      placeholder="Name of Authors"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="date_year"
                      value={this.state.date_year}
                      onChange={this.onChange}
                      placeholder="Year"
                    />
                  </td>

                  <td>
                    <Button
                      variant="outlined"
                      onClick={this.onAddClick.bind(this, "text_book")}
                      type="button"
                    >
                      +
                    </Button>
                  </td>
                </tr>
              </tbody>
              <tbody>
                {Array.isArray(this.state.text_book) && this.state.text_book.map((el, index) => (
                  <tr key={index}>
                   
                  
                    <td>
                    <textarea
                      className="form-control"
                      type="text"
                      name="title_book"
                      rows={5}
                      value={el.title_book}
                      onChange={e => this.handleChangeInput('title_book',e.target.value,index,'text_book')}
                      placeholder="Name of the Book/Text Book	"
                    />
                    
                    </td>
                    <td>
                    <textarea
                      className="form-control"
                      type="text"
                      name="published_by"
                      rows={5}
                      value={el.published_by}
                      onChange={e => this.handleChangeInput('published_by',e.target.value,index,'text_book')}
                      placeholder="Published By	"
                    />
                    
                    </td>
                    <td>
                    
                    <input
                      className="form-control"
                      type="text"
                      name="isbn_no"
                      rows={5}
                      value={el.isbn_no}
                      onChange={e => this.handleChangeInput('isbn_no',e.target.value,index,'text_book')}
                      placeholder="ISBN"
                    />
                    
                    </td>
                    <td>
                    <input
                      className="form-control"
                      type="text"
                      name="volume"
                      rows={5}
                      value={el.volume}
                      onChange={e => this.handleChangeInput('volume',e.target.value,index,'text_book')}
                      placeholder="volume"
                    /></td>
                    {/* <td>{el.page_nos}</td> */}
                    <td>
                    <input
                      className="form-control"
                      type="text"
                      name="volume"
                      rows={5}
                      value={el.edition}
                      onChange={e => this.handleChangeInput('edition',e.target.value,index,'text_book')}
                      placeholder="edition"
                    /></td>
                    <td>
                    <input
                      className="form-control"
                      type="text"
                      name="name_of_authors"
                      rows={5}
                      value={el.name_of_authors}
                      onChange={e => this.handleChangeInput('name_of_authors',e.target.value,index,'text_book')}
                      placeholder="Name of Authors"
                    /></td>



                    <td>
                    <input
                      className="form-control"
                      type="text"
                      name="date_year"
                      rows={5}
                      value={el.date_year}
                      onChange={e => this.handleChangeInput('date_year',e.target.value,index,'text_book')}
                      placeholder="Year"
                    /></td>

                    <td>
                      <Delete
                        onClick={this.onDelete.bind(
                          this,
                          index,
                          "text_book"
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </ExpansionPanelDetails>
            </ExpansionPanel>


            <br />
            <ExpansionPanel expanded={this.state.magazine_panel} onChange={() => this.onPanelClick('magazine_panel',!this.state.magazine_panel)}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography>Click here to Add Book/Journal/ Magazine Edited</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
            <table width="100%" className="table">
              <thead>
                <tr>
                  <td colSpan={6} align="center">
                    <Typography>Book/Journal/ Magazine Edited</Typography>
                  </td>
                </tr>
                <tr>
                  

                 

                  <td>Name of the
Book/journal/Magazine</td>
                  <td>Published By</td>
                  <td>ISBN / ISSN</td>
                            <td>Any other
information</td>
<td>Year</td>
                  <td>Click + to Add</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                 
                 

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title_book"
                      value={this.state.title_book}
                      onChange={this.onChange}
                      placeholder="Title of the
                      Book/journal/Magazine"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="published_by"
                      value={this.state.published_by}
                      onChange={this.onChange}
                      placeholder="Published By"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="isbn_no"
                      value={this.state.isbn_no}
                      onChange={this.onChange}
                      placeholder="ISBN / ISSN"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="other_info"
                      value={this.state.other_info}
                      onChange={this.onChange}
                      placeholder="Any other
                      information"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="date_year"
                      value={this.state.date_year}
                      onChange={this.onChange}
                      placeholder="Year"
                    />
                  </td>

                  <td>
                    <Button
                      variant="outlined"
                      onClick={this.onAddClick.bind(this, "magazine")}
                      type="button"
                    >
                      +
                    </Button>
                  </td>
                </tr>
              </tbody>
              <tbody>
                {Array.isArray(this.state.magazine) && this.state.magazine.map((el, index) => (
                  <tr key={index}>
                   
                  
                    <td>
                    
                    <textarea
                      className="form-control"
                      type="text"
                      name="title_book"
                      rows={5}
                      value={el.title_book}
                      onChange={e => this.handleChangeInput('title_book',e.target.value,index,'magazine')}
                      placeholder="Name of the Book/journal/Magazine	"
                    /></td>


                    <td>
                    
                    <textarea
                      className="form-control"
                      type="text"
                      name="published_by"
                      rows={5}
                      value={el.published_by}
                      onChange={e => this.handleChangeInput('published_by',e.target.value,index,'magazine')}
                      placeholder="Published By	"
                    />
                    
                    </td>
                    <td>
                    <input
                      className="form-control"
                      type="text"
                      name="isbn_no"
                      rows={5}
                      value={el.isbn_no}
                      onChange={e => this.handleChangeInput('isbn_no',e.target.value,index,'magazine')}
                      placeholder="ISBN / ISSN"
                    /></td>
                    <td>
                    
                    <input
                      className="form-control"
                      type="text"
                      name="other_info"
                      rows={5}
                      value={el.other_info}
                      onChange={e => this.handleChangeInput('other_info',e.target.value,index,'magazine')}
                      placeholder="Any other information	"
                    /></td>


                    <td>
                    <input
                      className="form-control"
                      type="text"
                      name="date_year"
                      rows={5}
                      value={el.date_year}
                      onChange={e => this.handleChangeInput('date_year',e.target.value,index,'magazine')}
                      placeholder="Year"
                    /></td>



                    <td>
                      <Delete
                        onClick={this.onDelete.bind(
                          this,
                          index,
                          "magazine"
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </ExpansionPanelDetails>
            </ExpansionPanel>
            <br />
            <ExpansionPanel expanded={this.state.popular_book_panel} onChange={() => this.onPanelClick('popular_book_panel',!this.state.popular_book_panel)}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography>Click here to Add Article in Popular Book/Magazine/Edited Book</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
            <table width="100%" className="table">
              <thead>
                <tr>
                  <td colSpan={6} align="center">
                    <Typography>Article in Popular Book/Magazine/Edited Book</Typography>
                  </td>
                </tr>
                <tr>
                  

                  <td>Title of the paper</td>

                  <td>Title of the Book</td>
                  <td>Published By</td>
                  <td>ISBN</td>
                  <td>Volume</td>
                  <td>Page Nos</td>
                  <td>Date / Year</td>

                  <td>Click + to Add</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                 
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title_paper"
                      value={this.state.title_paper}
                      onChange={this.onChange}
                      placeholder="Title of the Paper"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title_book"
                      value={this.state.title_book}
                      onChange={this.onChange}
                      placeholder="Title of the Book"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="published_by"
                      value={this.state.published_by}
                      onChange={this.onChange}
                      placeholder="Published By"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="isbn_no"
                      value={this.state.isbn_no}
                      onChange={this.onChange}
                      placeholder="ISBN No"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="volume"
                      value={this.state.volume}
                      onChange={this.onChange}
                      placeholder="Volume"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="page_nos"
                      value={this.state.page_nos}
                      onChange={this.onChange}
                      placeholder="Page Nos"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="date_year"
                      value={this.state.date_year}
                      onChange={this.onChange}
                      placeholder="Year"
                    />
                  </td>

                  <td>
                    <Button
                      variant="outlined"
                      onClick={this.onAddClick.bind(this, "popular_book")}
                      type="button"
                    >
                      +
                    </Button>
                  </td>
                </tr>
              </tbody>
              <tbody>
                {Array.isArray(this.state.popular_book) && this.state.popular_book.map((el, index) => (
                  <tr key={index}>
                    
                    <td>
                    
                    <textarea
                      className="form-control"
                      type="text"
                      name="title_paper"
                      rows={5}
                      value={el.title_paper}
                      onChange={e => this.handleChangeInput('title_paper',e.target.value,index,'popular_book')}
                      placeholder="Title of the paper	"
                    /></td>


                    <td>
                    
                    <textarea
                      className="form-control"
                      type="text"
                      name="title_book"
                      rows={5}
                      value={el.title_book}
                      onChange={e => this.handleChangeInput('title_book',e.target.value,index,'popular_book')}
                      placeholder="Title of the Book	"
                    /></td>


                    <td>
                    
                    <textarea
                      className="form-control"
                      type="text"
                      name="published_by"
                      rows={5}
                      value={el.published_by}
                      onChange={e => this.handleChangeInput('published_by',e.target.value,index,'popular_book')}
                      placeholder="Published By	"
                    /></td>


                    <td>
                    <input
                      className="form-control"
                      type="text"
                      name="isbn_no"
                      rows={5}
                      value={el.isbn_no}
                      onChange={e => this.handleChangeInput('isbn_no',e.target.value,index,'popular_book')}
                      placeholder="ISBN"
                    />
                    </td>
                    <td>
                    <input
                      className="form-control"
                      type="text"
                      name="volume"
                      rows={5}
                      value={el.volume}
                      onChange={e => this.handleChangeInput('volume',e.target.value,index,'popular_book')}
                      placeholder="Volumn"
                    />
                    
                    </td>
                    <td>
                    <input
                      className="form-control"
                      type="text"
                      name="page_nos"
                      rows={5}
                      value={el.page_nos}
                      onChange={e => this.handleChangeInput('page_nos',e.target.value,index,'popular_book')}
                      placeholder="Page Nos"
                    /></td>


                    <td>
                    
                    <input
                      className="form-control"
                      type="text"
                      name="date_year"
                      rows={5}
                      value={el.date_year}
                      onChange={e => this.handleChangeInput('date_year',e.target.value,index,'popular_book')}
                      placeholder="Year"
                    /></td>

                    <td>
                      <Delete
                        onClick={this.onDelete.bind(
                          this,
                          index,
                          "popular_book"
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </ExpansionPanelDetails>
            </ExpansionPanel>

            <br />
            <ExpansionPanel expanded={this.state.news_paper_panel} onChange={() => this.onPanelClick('news_paper_panel',!this.state.news_paper_panel)}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography>Click here to Add Publication in News Paper</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
            <table width="100%" className="table">
              <thead>
                <tr>
                  <td colSpan={4} align="center">
                    <Typography>Publication in News Paper</Typography>
                  </td>
                </tr>
                <tr>
                  <td>Date / Year</td>

                  <td>Title of the Article</td>

                  <td>Name of the News Paper</td>
                 

                  <td>Click + to Add</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="date_year"
                      value={this.state.date_year}
                      onChange={this.onChange}
                      placeholder="Date / Year"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title_paper"
                      value={this.state.title_paper}
                      onChange={this.onChange}
                      placeholder="Title of the Article"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title_book"
                      value={this.state.title_book}
                      onChange={this.onChange}
                      placeholder="Name of the News Paper"
                    />
                  </td>
                 

                  <td>
                    <Button
                      variant="outlined"
                      onClick={this.onAddClick.bind(this, "news_paper")}
                      type="button"
                    >
                      +
                    </Button>
                  </td>
                </tr>
              </tbody>
              <tbody>
                {Array.isArray(this.state.news_paper) && this.state.news_paper.map((el, index) => (
                  <tr key={index}>
                    <td>
                    
                    <input
                      className="form-control"
                      type="text"
                      name="date_year"
                      rows={5}
                      value={el.date_year}
                      onChange={e => this.handleChangeInput('date_year',e.target.value,index,'news_paper')}
                      placeholder="Year"
                    /></td>


                    <td>
                    
                    <input
                      className="form-control"
                      type="text"
                      name="title_paper"
                      rows={5}
                      value={el.title_paper}
                      onChange={e => this.handleChangeInput('title_paper',e.target.value,index,'news_paper')}
                      placeholder="Title of the Article	"
                    /></td>


                    <td>
                    
                    <input
                      className="form-control"
                      type="text"
                      name="title_book"
                      rows={5}
                      value={el.title_book}
                      onChange={e => this.handleChangeInput('title_book',e.target.value,index,'news_paper')}
                      placeholder="Name of the News Paper	"
                    /></td>


                    

                    <td>
                      <Delete
                        onClick={this.onDelete.bind(
                          this,
                          index,
                          "news_paper"
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </ExpansionPanelDetails>
</ExpansionPanel>
            <br />

            <ExpansionPanel expanded={this.state.other_publication_panel} onChange={() => this.onPanelClick('other_publication_panel',!this.state.other_publication_panel)}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography>Click here to Add Other Publication with details</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>

            <table width="100%" className="table">
              <thead>
                <tr>
                  <td colSpan={6} align="center">
                    <Typography>Other Publication with details</Typography>
                  </td>
                </tr>
                <tr>
                  

                 

                  <td>Title of the
                  Publication</td>
                  <td>Published By</td>
                  <td>ISBN / ISSN</td>
                  <td>Volume</td>
                  <td>Page Nos</td>
                  
                            <td>Any other
information</td>
<td>Year</td>
                  <td>Click + to Add</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  
                 

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title_book"
                      value={this.state.title_book}
                      onChange={this.onChange}
                      placeholder="Title of the
                      Publication"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="published_by"
                      value={this.state.published_by}
                      onChange={this.onChange}
                      placeholder="Published By"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="isbn_no"
                      value={this.state.isbn_no}
                      onChange={this.onChange}
                      placeholder="ISBN / ISSN"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="volume"
                      value={this.state.volume}
                      onChange={this.onChange}
                      placeholder="Volume"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="page_nos"
                      value={this.state.page_nos}
                      onChange={this.onChange}
                      placeholder="Page Nos"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="other_info"
                      value={this.state.other_info}
                      onChange={this.onChange}
                      placeholder="Any other
                      information"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="date_year"
                      value={this.state.date_year}
                      onChange={this.onChange}
                      placeholder="Year"
                    />
                  </td>

                  

                

                  <td>
                    <Button
                      variant="outlined"
                      onClick={this.onAddClick.bind(this, "other_publication")}
                      type="button"
                    >
                      +
                    </Button>
                  </td>
                </tr>
              </tbody>
              <tbody>
                {Array.isArray(this.state.other_publication) && this.state.other_publication.map((el, index) => (
                  <tr key={index}>
                    
                  
                    <td>
                    <textarea
                      className="form-control"
                      type="text"
                      name="other_publication"
                      rows={5}
                      value={el.other_publication}
                      onChange={e => this.handleChangeInput('other_publication',e.target.value,index,'other_publication')}
                      placeholder="Title of the Publication	"
                    /></td>


                    <td>
                    
                    <input
                      className="form-control"
                      type="text"
                      name="published_by"
                      rows={5}
                      value={el.published_by}
                      onChange={e => this.handleChangeInput('published_by',e.target.value,index,'other_publication')}
                      placeholder="Published By	"
                    />
                    
                    </td>
                    <td>
                    <input
                      className="form-control"
                      type="text"
                      name="isbn_no"
                      rows={5}
                      value={el.isbn_no}
                      onChange={e => this.handleChangeInput('isbn_no',e.target.value,index,'other_publication')}
                      placeholder="ISBN / ISSN	"
                    />
                    
                    </td>
                    <td>
                    
                    <input
                      className="form-control"
                      type="text"
                      name="volume"
                      rows={5}
                      value={el.volume}
                      onChange={e => this.handleChangeInput('volume',e.target.value,index,'other_publication')}
                      placeholder="Volume"
                    />
                    
                    </td>
                    <td>
                    
                    <input
                      className="form-control"
                      type="text"
                      name="page_nos"
                      rows={5}
                      value={el.page_nos}
                      onChange={e => this.handleChangeInput('page_nos',e.target.value,index,'other_publication')}
                      placeholder="Page Nos"
                    />
                    
                    </td>
                    <td>
                    
                    <input
                      className="form-control"
                      type="text"
                      name="other_info"
                      rows={5}
                      value={el.other_info}
                      onChange={e => this.handleChangeInput('other_info',e.target.value,index,'other_publication')}
                      placeholder="Any other information"
                    />
                    
                    </td>
                    <td>
                    
                    <input
                      className="form-control"
                      type="text"
                      name="date_year"
                      rows={5}
                      value={el.date_year}
                      onChange={e => this.handleChangeInput('date_year',e.target.value,index,'other_publication')}
                      placeholder="Year"
                    /></td>



                    <td>
                      <Delete
                        onClick={this.onDelete.bind(
                          this,
                          index,
                          "other_publication"
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </ExpansionPanelDetails>
            </ExpansionPanel>

            <br />
            <ExpansionPanel expanded={this.state.econtent_panel} onChange={() => this.onPanelClick('econtent_panel',!this.state.econtent_panel)}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography>Click here to Add E-CONTENT DEVELOPMENT</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>

            <table width="100%" className="table">
              <thead>
                <tr>
                  <td colSpan={6} align="center">
                    <Typography>E-CONTENT DEVELOPMENT</Typography>
                  </td>
                </tr>
                <tr>
                  <td>Year</td>

                 

                  <td>Type of
E-content</td>
<td>Title of the Publication</td>
                  <td>Published By</td>
                  
                            <td>Remarks</td>
                  <td>Click + to Add</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="date_year"
                      value={this.state.date_year}
                      onChange={this.onChange}
                      placeholder="Year"
                    />
                  </td>
                 

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title_paper"
                      value={this.state.title_paper}
                      onChange={this.onChange}
                      placeholder="Type of
                      E-content"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title_book"
                      value={this.state.title_book}
                      onChange={this.onChange}
                      placeholder="Title of the Publication"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="published_by"
                      value={this.state.published_by}
                      onChange={this.onChange}
                      placeholder="Published By"
                    />
                  </td>

                  
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="other_info"
                      value={this.state.other_info}
                      onChange={this.onChange}
                      placeholder="Remarks"
                    />
                  </td>

                  <td>
                    <Button
                      variant="outlined"
                      onClick={this.onAddClick.bind(this, "econtent")}
                      type="button"
                    >
                      +
                    </Button>
                  </td>
                </tr>
              </tbody>
              <tbody>
                {Array.isArray(this.state.econtent) && this.state.econtent.map((el, index) => (
                  <tr key={index}>
                    <td>
                    
                    <input
                      className="form-control"
                      type="text"
                      name="date_year"
                      rows={5}
                      value={el.date_year}
                      onChange={e => this.handleChangeInput('date_year',e.target.value,index,'econtent')}
                      placeholder="Year"
                    /></td>


                  
                    <td>
                    
                    <textarea
                      className="form-control"
                      type="text"
                      name="title_paper"
                      rows={5}
                      value={el.title_paper}
                      onChange={e => this.handleChangeInput('title_paper',e.target.value,index,'econtent')}
                      placeholder="Type of E-content	"
                    />
                    
                    </td>
                    <td>
                    
                    <textarea
                      className="form-control"
                      type="text"
                      name="title_book"
                      rows={5}
                      value={el.title_book}
                      onChange={e => this.handleChangeInput('title_book',e.target.value,index,'econtent')}
                      placeholder="Title of the Publication"
                    />
                    
                    </td>
                    <td>
                    <input
                      className="form-control"
                      type="text"
                      name="published_by"
                      rows={5}
                      value={el.published_by}
                      onChange={e => this.handleChangeInput('published_by',e.target.value,index,'econtent')}
                      placeholder="Published By	"
                    /></td>


                    <td>
                    
                    <input
                      className="form-control"
                      type="text"
                      name="other_info"
                      rows={5}
                      value={el.other_info}
                      onChange={e => this.handleChangeInput('other_info',e.target.value,index,'econtent')}
                      placeholder="Remarks"
                    /></td>



                    <td>
                      <Delete
                        onClick={this.onDelete.bind(
                          this,
                          index,
                          "econtent"
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </ExpansionPanelDetails>
            </ExpansionPanel>
            <br />

            <div align="right">
              <Button size="sm" variant="info" type="submit">
                Submit
              </Button>
            </div>
          </form>
        )}
      </div>
    );
  }
}

export default FacultyPublications;
