import React, { Component } from "react";
import {
  Typography,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Chip,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, FormControl, Card } from "react-bootstrap";
import { PostData, GetData, DeleteData, PutData } from "../../api/service";
import Snack from "../Snackbar/Snack";
import FileUpload from "../AddNew/FileUpload";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import history from "../../history";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

class FacultyInvolvement extends Component {
  state = {
    m_type: this.props.m_type,
    employee_id: this.props.employee_id,
    email: this.props.email,
    message: "",
    open: false,
    isLoaded: false,
    name: "",
    position: "",
    from: "",
    till: "",
   
    other_info:'',
    college_activities: [],
    college_activities_panel:false,
    social_activities: [],
    social_activities_panel:false,
    
  };

  clearData = ()=>{
      this.setState({
        name: "",
        position: "",
        from: "",
        till: "",
       
        other_info:'',
      })
  }

  onPanelClick = (type,flag)=>{
        this.clearData();
        this.setState({
            college_activities_panel:type=='college_activities_panel'?flag:false,
            social_activities_panel:type=='social_activities_panel'?flag:false,

        })
    
  }

  handleChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    //console.log(this.props);

    GetData(`/admin/${this.props.employee_id}/getsingleempolyeedataapi`).then(
      (resp) => {
        if (resp) {
          this.setState({
            ...resp,

           
          });
        }
        this.setState({
          isLoaded: true
        })
      }
    );
  }

  //   componentDidUpdate(prevProps){
  //     if(this.props !== prevProps){
  //         this.setState({
  //             seminar:this.props.seminar

  //         })
  //     }
  // }

  onSubmit = (e) => {
    e.preventDefault();

    let d = this.state;

    PostData(`/admin/${this.props.employee_id}/addemployeeapi`, d).then(
      (resp) => {
        //console.log(resp)
        this.setState({
          message: resp,
          open: true,
        });
      }
    );
  };

  onAddClick = (type) => {
    if (type == "college_activities") {
      let d = {
        name: this.state.name,
        position: this.state.position,
        from: this.state.from,
        till: this.state.till,
        other_info: this.state.other_info,
      };

      let college_activities = this.state.college_activities != null ? this.state.college_activities : [];
      college_activities.push(d);
      this.setState(
        {
            college_activities,
        },
        () => {
          this.setState({
            name: "",
            position: "",
            from: "",
            till: "",
           
            other_info:'',
          });
        }
      );
    }

    else  if (type == "social_activities") {
        let d = {
            name: this.state.name,
            position: this.state.position,
            from: this.state.from,
            till: this.state.till,
            other_info: this.state.other_info,
          };
  
        let social_activities = this.state.social_activities != null ? this.state.social_activities : [];
        social_activities.push(d);
        this.setState(
          {
            social_activities,
          },
          () => {
            this.setState({
              name: "",
              position: "",
              from: "",
              till: "",
             
              other_info:'',
            });
          }
        );
      }

  };

  onDelete = (index, type) => {
    if (type == "college_activities") {
      let college_activities = this.state.college_activities;
      college_activities.splice(index, 1);

      this.setState({
        college_activities,
      });
    }

    else if (type == "social_activities") {
        let social_activities = this.state.social_activities;
        social_activities.splice(index, 1);
  
        this.setState({
            social_activities,
        });
      }

      
  };

  render() {
    return (
      <div>
        {this.state.open ? (
          <Snack
            open={this.state.open}
            message={this.state.message}
            handleClose={() => {
              this.setState({
                open: false,
                message: "",
              });
            }}
          />
        ) : null}

        {this.state.isLoaded && (
          <form onSubmit={this.onSubmit}>
            <Typography variant="h6" gutterBottom>
              {this.props.title}
            </Typography>

            <ExpansionPanel expanded={this.state.college_activities_panel} onChange={() => this.onPanelClick('college_activities_panel',!this.state.college_activities_panel)}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography>Click here to Add In Campus Activities</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>

            <table width="100%" className="table">
              <thead>
                <tr>
                  <td colSpan={6} align="center">
                    <Typography>Involvement in Campus Activities</Typography>
                  </td>
                </tr>
                <tr>
                  <td rowSpan={2}>Name of the Committee / Scheme</td>

                  <td rowSpan={2}>Position/Responsibility/
Capacity Hold</td>

                  <td colSpan={2}>Period</td>
                  <td colSpan={2}>Other Information</td>
                
                  <td rowSpan={2} >Click + to Add</td>
                </tr>
                <tr>
                    <td>From</td>
                    <td>To</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      value={this.state.name}
                      onChange={this.onChange}
                      placeholder="Name of the Committee / Scheme"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="position"
                      value={this.state.position}
                      onChange={this.onChange}
                      placeholder="Position/Responsibility/
                      Capacity Hold"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="from"
                      value={this.state.from}
                      onChange={this.onChange}
                      placeholder="From"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="till"
                      value={this.state.till}
                      onChange={this.onChange}
                      placeholder="Till"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="other_info"
                      value={this.state.other_info}
                      onChange={this.onChange}
                      placeholder="Other Information"
                    />
                  </td>

                  <td>
                    <Button
                      variant="outlined"
                      onClick={this.onAddClick.bind(this, "college_activities")}
                      type="button"
                    >
                      +
                    </Button>
                  </td>
                </tr>
              </tbody>
              <tbody>
                {Array.isArray(this.state.college_activities) && this.state.college_activities.map((el, index) => (
                  <tr key={index}>
                    <td>{index+1}. {el.name}</td>
                    <td>{el.position}</td>
                    <td>{el.from}</td>
                    <td>{el.till}</td>
                    <td>{el.other_info}</td>

                    <td>
                      <Delete
                        onClick={this.onDelete.bind(
                          this,
                          index,
                          "college_activities"
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

 </ExpansionPanelDetails>
 </ExpansionPanel>

 <br />


 <ExpansionPanel expanded={this.state.social_activities_panel} onChange={() => this.onPanelClick('social_activities_panel',!this.state.social_activities_panel)}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography>Click here to Add Extension Activities</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>

            <table width="100%" className="table">
              <thead>
                <tr>
                  <td colSpan={6} align="center">
                    <Typography>Involvement in Extension Activities</Typography>
                  </td>
                </tr>
                <tr>
                  <td rowSpan={2}>Name of the Committee / Scheme</td>

                  <td rowSpan={2}>Position/Responsibility/
Capacity Hold</td>

                  <td colSpan={2}>Period</td>
                  <td colSpan={2}>Other Information</td>

                
                  <td rowSpan={2} align="right">Click + to Add</td>
                </tr>
                <tr>
                    <td>From</td>
                    <td>To</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      value={this.state.name}
                      onChange={this.onChange}
                      placeholder="Name of the Committee / Scheme"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="position"
                      value={this.state.position}
                      onChange={this.onChange}
                      placeholder="Position/Responsibility/
                      Capacity Hold"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="from"
                      value={this.state.from}
                      onChange={this.onChange}
                      placeholder="From"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="till"
                      value={this.state.till}
                      onChange={this.onChange}
                      placeholder="Till"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="other_info"
                      value={this.state.other_info}
                      onChange={this.onChange}
                      placeholder="Other Information"
                    />
                  </td>

                  <td>
                    <Button
                      variant="outlined"
                      onClick={this.onAddClick.bind(this, "social_activities")}
                      type="button"
                    >
                      +
                    </Button>
                  </td>
                </tr>
              </tbody>
              <tbody>
                {Array.isArray(this.state.social_activities) && this.state.social_activities.map((el, index) => (
                  <tr key={index}>
                    <td>{index+1}. {el.name}</td>
                    <td>{el.position}</td>
                    <td>{el.from}</td>
                    <td>{el.till}</td>
                    <td>{el.other_info}</td>

                    <td>
                      <Delete
                        onClick={this.onDelete.bind(
                          this,
                          index,
                          "social_activities"
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

 </ExpansionPanelDetails>
 </ExpansionPanel>
 <br />

            <div align="right">
              <Button size="sm" variant="info" type="submit">
                Submit
              </Button>
            </div>
          </form>
        )}
      </div>
    );
  }
}

export default FacultyInvolvement;
