import React, { Component } from "react";
import {
  withStyles,
  CardHeader,
  Avatar,
  Typography,
  CardContent,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  CardActions,
  Button,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Chip,
} from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import { DeleteData, GetData, PostData, PutData } from "../../../api/service";
import Snack from "../../Snackbar/Snack";
import FileUpload from "../../AddNew/FileUpload";
import Rich from "../../AddNew/Rich";
import { confirmAlert } from "react-confirm-alert";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import { Card } from "react-bootstrap";
import MUIDataTable from "mui-datatables";
const styles = (theme) => ({
  textField: {
    width: "100%",
  },
  formControl: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
  },
});

class IQACForm extends Component {
  state = {
    date: "",
    type: this.props.code,
    posted_as: "",
    type: "",
    title: "",
    description: "",
    upload_info: [],
    opening_date: "",
    dept_code: "DISPUR",
    closing_date: "",
    open: false,
    edit: false,
    loadFile: true,
    message: "",
    iqactype: [
      // {
      //   name: 'IQAC Stretegies',
      //   value: 'IQAC_STRETEGIES',
      // },

      {
        name: "IQAC Self Study Reports",
        value: "IQAC_SSR",
      },
      {
        name: "IQAC AQAR",
        value: "IQAC_AQAR",
      },
      {
        name: "IQAC Action Taken Report",
        value: "IQAC_ATR",
      },
      {
        name: "IQAC Meeting Minutes",
        value: "IQAC_MIUNTES",
      },
      {
        name: "IIC Activity/Events",
        value: "IQAC_IIC",
      },
      {
        name: "IIC Gallery",
        value: "IQAC_IIC_GALLERY",
      },
      {
        name: "UBA Activity",
        value: "IQAC_UBA",
      },
      {
        name: "UBA Gallery",
        value: "IQAC_UBA_GALLERY",
      },
      {
        name: "SOPs & Policies",
        value: "IQAC_SOP",
      },
      {
        name: "MoUs & Collaborations",
        value: "IQAC_COLLBORATIONS",
      },
      {
        name: "AISHE Certificates",
        value: "IQAC_AISHE",
      },
      {
        name: "NAAC Links (Hidden)",
        value: "IQAC_NAAC_LINK",
      },
      {
        name: "Notifications",
        value: "IQAC_NOTICE",
      },
      {
        name: "Declaration",
        value: "IQAC_DECLARATION",
      },
      {
        name: "Institutional Perspective Plan",
        value: "IQAC_IPP",
      },
      {
        name: "Best Practices",
        value: "IQAC_BEST_PRACTICE",
      },

      {
        name: "Institutional Distinctiveness",
        value: "IQAC_INSTITUTIONAL_DISTINCTIVENESS",
      },
      {
        name: "Course Outcomes & Program Outcomes",
        value: "IQAC_CO",
      },
      {
        name: "Seminars/Webinars/Conferences",
        value: "IQAC_SEMINARS",
      },
      {
        name: "Organogram",
        value: "IQAC_ORGANOGRAM",
      },
      {
        name: "Research Papers/Books/Edited Books/Book Chapters",
        value: "IQAC_RESEARCH_PAPERS",
      },
      // {
      //   name: 'IQAC Feedback Form',
      //   value: 'IQAC_FEEDBACK_FORM',
      // },
      // {
      //   name: 'IQAC Feedback Analysis',
      //   value: 'IQAC_FEEDBACK_ANALYSIS',
      // },
      // {
      //   name: 'IQAC Student Satisfactory Survey',
      //   value: 'IQAC_SSS',
      // },
      // {
      //   name: 'IQAC Event/News',
      //   value: 'IQAC_EVENT',
      // },
    ],
  };

  __getInitialState() {
    this.setState(
      {
        date: "",
        type: this.props.type,
        posted_as: "",
        type: "",
        title: "",
        description: "",
        upload_info: [],
        opening_date: "",
        dept_code: "DISPUR",
        closing_date: "",
        edit: false,
        loadFile: false,
      },
      () => {
        this.setState({
          loadFile: true,
        });
      }
    );
  }

  __loadType() {
    this.setState({
      type: this.props.code,
    });
  }

  __getDeptData = () => {
    let type = "";

    type = "NONE";

    GetData(`/DISPUR/${type}/getnoticesbytypedept`).then((resp) => {
      // filter those
      let dd = this.state.iqactype.map((el) => el.value);
      this.setState({
        data: resp.filter((el) => dd.includes(el.type)),
        isDataLoaded: true,
      });
    });
  };

  componentDidMount() {
    this.__loadType();
    this.__getDeptData();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.__loadType();
      this.__getDeptData();
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleRichChange = (data) => {
    this.setState({
      description: data,
    });
  };

  onSetFile = (data) => {
    //console.log(data)
    this.setState({
      uploadInfo: data,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    let data = {
      ...this.state,
    };

    //console.log(data);
    if (this.state.edit) {
      PutData(`/admin/${this.state.id}/editnotices`, data).then((resp) => {
        //console.log(resp)
        this.setState(
          {
            message: resp,
            open: true,
          },
          () => {
            this.__getInitialState();
            this.__getDeptData();
          }
        );
      });
    } else {
      PostData(`/${this.props.apikey}/createnotice`, data)
        .then((resp) => {
          //console.log(resp)
          this.setState({
            open: true,
            message: resp,
          });
        })
        .then(() => {
          this.__getInitialState();
          this.__getDeptData();
        });
    }
  };

  onDeleteClick = (el) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: this.onDeleteConfirm.bind(this, el.id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  onDeleteConfirm = (id) => {
    DeleteData(`/${this.props.apikey}/${id}/deletenotice`).then((resp) => {
      this.setState(
        {
          message: resp,
          open: true,
        },
        () => this.__getDeptData()
      );
    });
  };

  onEditClick = (el) => {
    this.setState(
      {
        ...el,
        edit: true,
        loadFile: false,
      },
      () => {
        this.setState({
          loadFile: true,
        });
      }
    );
  };

  getNameByType = (type) => {
    let dd = this.state.iqactype;

    let dd1 = dd.find((el) => el.value == type);

    if (dd1) {
      return dd1.name;
    } else {
      return "";
    }
  };

  render() {
    let { classes } = this.props;

    let i = (
      <Grid key={0} item lg={4} sm={12}>
        <TextField
          label="Opening Date"
          className={classes.textField}
          type="date"
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          name="opening_date"
          value={this.state.opening_date}
          onChange={this.handleChange}
        />
      </Grid>
    );

    let j = (
      <Grid key={1} item lg={4} sm={12}>
        <TextField
          label="Closing Date"
          className={classes.textField}
          type="date"
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          name="closing_date"
          value={this.state.closing_date}
          onChange={this.handleChange}
        />
      </Grid>
    );
    return (
      <div>
        {this.state.open ? (
          <Snack
            open={this.state.open}
            handleClose={() => {
              this.setState({
                open: false,
                message: "",
              });
            }}
            message={this.state.message}
          />
        ) : null}
        <form onSubmit={this.onSubmit}>
          <Card>
            <CardHeader
              avatar={
                <Avatar>
                  <Add />
                </Avatar>
              }
              title={
                <Typography variant="h6">
                  Add New IQAC Meterial
                  {/* {this.props.title} */}
                </Typography>
              }
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item lg={4} sm={12}>
                  <TextField
                    label="Date"
                    className={classes.textField}
                    type="date"
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required={true}
                    name="date"
                    value={this.state.date}
                    onChange={this.handleChange}
                  />
                </Grid>

                <Grid item lg={4} sm={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel>Post As</InputLabel>
                    <Select
                      value={this.state.posted_as}
                      onChange={this.handleChange}
                      input={
                        <OutlinedInput labelWidth={200} name="posted_as" />
                      }
                      required={true}
                      name="posted_as"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="Dispur College">Dispur College</MenuItem>
                      <MenuItem value="IQAC Coordinator">
                        IQAC Coordinator
                      </MenuItem>
                      <MenuItem value="Principal">Principal</MenuItem>
                      <MenuItem value="Student's Union">
                        Student's Union
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item lg={4} sm={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel>Type</InputLabel>
                    <Select
                      name="type"
                      value={this.state.type}
                      onChange={this.handleChange}
                      input={<OutlinedInput labelWidth={200} name="type" />}
                      required={true}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {console.log(this.props.title)}
                      {this.state.iqactype.map((el) => (
                        <MenuItem value={el.value}>{el.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {this.state.type === "COLLEGE_TENDER" ||
                this.state.type === "events"
                  ? [i, j]
                  : null}

                <Grid item lg={12} sm={12}>
                  <TextField
                    label="Title"
                    className={classes.textField}
                    type="text"
                    margin="normal"
                    variant="outlined"
                    required={true}
                    name="title"
                    value={this.state.title}
                    onChange={this.handleChange}
                  />
                </Grid>

                <Grid item lg={12} sm={12}>
                  <Rich
                    placeholder="Description....."
                    onChange={this.handleRichChange}
                  />
                </Grid>

                <Grid item lg={12} sm={12}>
                  {this.state.loadFile && this.state.type !== "" && (
                    <FileUpload
                      dp={this.state.upload_info}
                      apikey={this.props.apikey}
                      type={this.state.type}
                      setDp={this.onSetFile.bind(this)}
                    />
                  )}
                </Grid>
              </Grid>
            </CardContent>

            <CardActions>
              {this.state.edit ? (
                <div align="right">
                  <Button size="sm" variant="danger" type="submit">
                    Update
                  </Button>
                  &nbsp;
                  <Button
                    size="sm"
                    variant="danger"
                    type="button"
                    onClick={() => this.__getInitialState()}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                <div align="right">
                  <Button size="sm" variant="info" type="submit">
                    Submit
                  </Button>
                </div>
              )}
            </CardActions>
          </Card>
        </form>

        <br />

        <Card>
          <Card.Body>
            {this.state.isDataLoaded ? (
              <div>
                <MUIDataTable
                  title={`IQAC Files`}
                  columns={[
                    {
                      name: "#",
                      field: "index",
                      options: {
                        filter: true,
                        sort: true,
                      },
                    },

                    {
                      name: "Type",
                      options: {
                        filter: true,
                        sort: true,
                      },
                    },
                    {
                      name: "Title",
                      options: {
                        filter: true,
                        sort: true,
                      },
                    },
                    {
                      name: "Date",
                      options: {
                        filter: true,
                        sort: true,
                      },
                    },
                    {
                      name: "File",
                      options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (el) => {
                          return (
                            <ul>
                              {Array.isArray(el.upload_info) &&
                                el.upload_info.map((el1, index1) => (
                                  <li key={index1}>
                                    <a style={{ color: "blue" }} href={el1.url}>
                                      Attachment {index1 + 1} (write click and
                                      "copy link address")
                                    </a>
                                  </li>
                                ))}
                            </ul>
                          );
                        },
                      },
                    },

                    {
                      name: "Action",
                      options: {
                        filter: false,
                        sort: false,
                        download: false,
                        customBodyRender: (el) => {
                          return (
                            <div>
                              <Edit onClick={this.onEditClick.bind(this, el)} />
                              &nbsp;
                              <Delete onClick={this.onDeleteClick.bind(this, el)} />
                            </div>
                          );
                        },
                      },
                    },
                  ]}
                  options={{
                    filterType: "checkbox",
                    selectableRows: false,
                    responsive: "scroll",
                    rowsPerPage: 100
                  }}
                  data={[
                    ...this.state.data.map((el, index) => [
                      index + 1,
                      this.getNameByType(el.type),
                      el.title,
                      el.date,
                      el,
                      el,
                    ]),
                  ]}
                />

                {/* <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell width="10%">Type</TableCell>
                      <TableCell width="20%">Title</TableCell>
                      <TableCell width="5%">Date</TableCell>
                      <TableCell width="30%">File</TableCell>

                      <TableCell>Edit</TableCell>
                      <TableCell>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.data.map((el, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell style={{ fontWeight: 700 }}>
                          {this.getNameByType(el.type)}
                        </TableCell>
                        <TableCell>{el.title}</TableCell>
                        <TableCell>{el.date}</TableCell>

                        <TableCell>
                          <ul>
                            {Array.isArray(el.upload_info) &&
                              el.upload_info.map((el1, index1) => (
                                <li key={index1}>
                                  <a style={{ color: "blue" }} href={el1.url}>
                                    Attachment {index1 + 1} (write click and
                                    "copy link address")
                                  </a>
                                </li>
                              ))}
                          </ul>
                        </TableCell>

                        <TableCell>
                          <Edit onClick={this.onEditClick.bind(this, el)} />
                        </TableCell>
                        <TableCell>
                          <Delete onClick={this.onDeleteClick.bind(this, el)} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table> */}
              </div>
            ) : (
              <Typography>Loading . . .</Typography>
            )}
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(IQACForm);
