import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import { Tabs, Tab } from "react-bootstrap";
import { GetData } from "../../api/service";

import FacultyOthers from "./FacultyOthers";
import FacultyHomePage from "./FacultyHomePage";
import FacultyBasic from "./FacultyBasic";
import FacultySeminar from "./FacultySeminar";
import FacultyResearch from "./FacultyResearch";
import FacultyPublications from "./FacultyPublications";
import FacultyPhd from "./FacultyPhd";
import FacultyWorkshop from "./FacultyWorkshop";
import FacultyInvolvement from "./FacultyInvolvement";
import FacultyAchievements from "./FacultyAchievements";
import FacultyAward from "./FacultyAward";
import FacultyResearchGuideship from "./FacultyResearchGuideship";
import ChangePassword from "../Settings/ChangePassword";
import AuthService from "../../auth_components/js/AuthService";
import FacultyProjectGuideship from "./FacultyProjectGuideship";
import FacultyResourcePerson from "./FacultyResourcePerson";
import FacultyGallery from "./FacultyGallery";
import Gallery from "../Gallery/Gallery";

const smallTab = [
  {
    title: "Home",
    active: 0,
    access: [
      "FACULTY",
      "PRINCIPAL",
      "VICE PRINCIPAL",
      "OFFICE",
      "LIB",
      "BEARER",
    ],
  },
  {
    title: "Basic",
    active: 1,
    access: [
      "FACULTY",
      "PRINCIPAL",
      "VICE PRINCIPAL",
      "OFFICE",
      "LIB",
      "BEARER",
    ],
  },

  {
    title: "Seminar/Workshop/Conference Participation",
    active: 2,
    access: ["FACULTY", "PRINCIPAL", "VICE PRINCIPAL", "LIB"],
  },
  {
    title: "Research Projects (ongoing/completed)",
    active: 3,
    access: ["FACULTY", "PRINCIPAL", "VICE PRINCIPAL", "LIB"],
  },
  {
    title: "Publications",
    active: 4,
    access: ["FACULTY", "PRINCIPAL", "VICE PRINCIPAL", "OFFICE", "LIB"],
  },

  {
    title: "Orientation Programme/Refresher Course/Short Term course",
    active: 5,
    access: [
      "FACULTY",
      "PRINCIPAL",
      "VICE PRINCIPAL",
      "OFFICE",
      "LIB",
      "BEARER",
    ],
  },
  {
    title: "Academic Details",
    active: 6,
    access: ["FACULTY", "PRINCIPAL", "VICE PRINCIPAL", "LIB"],
  },
  {
    title: "Involvement",
    active: 7,
    access: ["FACULTY", "PRINCIPAL", "VICE PRINCIPAL", "OFFICE", "LIB"],
  },
  {
    title: "Any other Information",
    active: 8,
    access: ["FACULTY", "PRINCIPAL", "VICE PRINCIPAL", "OFFICE", "LIB"],
  },
  {
    title: "Awards",
    active: 9,
    access: ["FACULTY", "PRINCIPAL", "VICE PRINCIPAL", "OFFICE", "LIB"],
  },
  {
    title: "Links",
    active: 10,
    access: ["FACULTY", "PRINCIPAL", "VICE PRINCIPAL", "OFFICE", "LIB"],
  },
  {
    title: "Research Scholars",
    active: 11,
    access: ["FACULTY", "PRINCIPAL", "VICE PRINCIPAL", "OFFICE", "LIB"],
  },
  {
    title: "Project Guideship / Supervisor",
    active: 12,
    access: ["FACULTY", "PRINCIPAL", "VICE PRINCIPAL", "OFFICE", "LIB"],
  },
  {
    title: "Resource Person Invitee",
    active: 13,
    access: ["FACULTY", "PRINCIPAL", "VICE PRINCIPAL", "OFFICE", "LIB"],
  },

  {
    title: "Personal Gallery",
    active: 14,
    access: ["FACULTY", "PRINCIPAL", "VICE PRINCIPAL", "OFFICE", "LIB"],
  },
  {
    title: "Change Password",
    active: 15,
    access: ["FACULTY", "PRINCIPAL", "VICE PRINCIPAL", "OFFICE", "LIB"],
  },
];

class FacultyHomeLanding extends Component {
  state = {
    active: 0,
    employee_id: "",
    data: {},
    isDeptLoaded: false,
  };

  __getDeptData = (employee_id) => {
    GetData(`/admin/${employee_id}/getsingleempolyeedata`).then((resp) => {
      if (resp != false) {
        this.setState({
          data: resp,
          active: 0,
          isDeptLoaded: true,
        });
      } else {
        this.setState({
          data: {},
          active: 0,
          isDeptLoaded: true,
        });
      }
    });
  };

  componentDidMount() {
    let employee_id = this.props.match.params.employee_id;
    this.setState(
      {
        employee_id: employee_id,
      },
      () => {
        this.__getDeptData(this.state.employee_id);
      }
    );
  }

  render() {
    return (
      <div>
        <Typography variant="h6">
          {this.state.data.name} / {this.state.data.designation}
        </Typography>
        <Typography variant="subtitle2">
          Department: {this.state.data.dept_code}
        </Typography>

        <br />

        {this.state.isDeptLoaded ? (
          <Tabs
            activeKey={this.state.active}
            onSelect={(k) => this.setState({ active: k })}
          >
            {smallTab.map((el, index) => {
              if (el.access.includes(this.state.data.type))
                return (
                  <Tab key={index} eventKey={index} title={el.title}>
                    <br />
                  </Tab>
                );
            })}
          </Tabs>
        ) : null}

        {this.state.isDeptLoaded && (
          <React.Fragment>
            {this.state.active == 0 ? (
              <FacultyHomePage
                {...this.state.data}
                type="liveNow"
                title="Faculty Home Page"
                value={this.state.data.liveNow}
                employee_id={this.state.employee_id}
              />
            ) : null}

            {this.state.active == 1 ? (
              <FacultyBasic
                {...this.state.data}
                m_type="basic"
                title="Basic Details"
                value={this.state.data.basic}
                employee_id={this.state.employee_id}
              />
            ) : null}

            {this.state.active == 2 ? (
              <FacultySeminar
                {...this.state.data}
                m_type="seminar"
                title="Seminar Participated"
                value={this.state.data.seminar}
                employee_id={this.state.employee_id}
              />
            ) : null}

            {this.state.active == 3 ? (
              <FacultyResearch
                {...this.state.data}
                m_type="research"
                title="Research Projects"
                value={this.state.data.research}
                employee_id={this.state.employee_id}
              />
            ) : null}

            {this.state.active == 4 ? (
              <FacultyPublications
                {...this.state.data}
                m_type="publications"
                title="Publications"
                value={this.state.data.publications}
                employee_id={this.state.employee_id}
              />
            ) : null}

            {this.state.active == 5 ? (
              <FacultyWorkshop
                {...this.state.data}
                m_type="workshop"
                title="Orientation programme/Refresher Course/Short term course"
                value={this.state.data.workshop}
                employee_id={this.state.employee_id}
              />
            ) : null}

            {this.state.active == 6 ? (
              <FacultyPhd
                {...this.state.data}
                m_type="phd"
                title="PhD and M.Phill"
                value={this.state.data.phd}
                employee_id={this.state.employee_id}
              />
            ) : null}

            {this.state.active == 7 ? (
              <FacultyInvolvement
                {...this.state.data}
                m_type="involvement"
                title="Involvement in College and Outside"
                value={this.state.data.involvement}
                employee_id={this.state.employee_id}
              />
            ) : null}

            {this.state.active == 8 ? (
              <FacultyAchievements
                {...this.state.data}
                m_type="cocurricular"
                title="Any other Information"
                value={this.state.data.cocurricular}
                employee_id={this.state.employee_id}
              />
            ) : null}

            {this.state.active == 9 ? (
              <FacultyAward
                {...this.state.data}
                m_type="awards"
                title="Awards Received"
                value={this.state.data.awards}
                employee_id={this.state.employee_id}
              />
            ) : null}

            {this.state.active == 10 ? (
              <FacultyOthers
                {...this.state.data}
                m_type="others"
                title="Others"
                value={this.state.data.others}
                employee_id={this.state.employee_id}
              />
            ) : null}

            {this.state.active == 11 ? (
              <FacultyResearchGuideship
                {...this.state.data}
                m_type="guideship"
                title="Research Guideship"
                value={this.state.data.guideship}
                employee_id={this.state.employee_id}
              />
            ) : null}

            {this.state.active == 12 ? (
              <FacultyProjectGuideship
                {...this.state.data}
                m_type="project_guideship"
                title="Project Guideship / Supervisor"
                value={this.state.data.project_guideship}
                employee_id={this.state.employee_id}
              />
            ) : null}

            {this.state.active == 13 ? (
              <FacultyResourcePerson
                {...this.state.data}
                m_type="resource_person"
                title="Resource Person Invitee"
                value={this.state.data.resource_person}
                employee_id={this.state.employee_id}
              />
            ) : null}

            {this.state.active == 14 ? (
              <Gallery
                type="FACULTY"
                dept={this.state.employee_id}
              />
            ) : null}

            {this.state.active == 15 ? (
              <ChangePassword
                {...this.state.data}
                username={new AuthService().getUserName()}
                employee_id={this.state.employee_id}
              />
            ) : null}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default FacultyHomeLanding;
