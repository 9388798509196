import React from 'react';
import PropTypes from 'prop-types';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import AuthService from './../auth_components/js/AuthService';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'


const styles = theme => ({

});

const menuListObject = [
    {
        title: "College",
        link: "/college",
        submenu: []
    },
    {
        title: "Departments",
        link: "/departments",
        submenu: []
    },
    // {
    //     title: "Notifications",
    //     link: "/notices",
    //     submenu: []
    // },
    // {
    //     title: "Tenders",
    //     link: "/tender",
    //     submenu: []
    // },
    // {
    //     title: "News",
    //     link: "/news",
    //     submenu: []
    // },
    // {
    //     title: "Events",
    //     link: "/events",
    //     submenu: []
    // },
    {
        title: "View All",
        link: "/view",
        submenu: []
    },
    {
        title: "IQAC",
        link: "/iqac",
        submenu: []
    },
    // {
    //     title: "NIRF",
    //     link: "/nirf",
    //     submenu: []
    // },
    {
        title: "GALLERY",
        link: "/gallery",
        submenu: []
    },
    {
        title: "PHOTOS",
        link: "/photos",
        submenu: []
    },
    {
        title: "NSS",
        link: "/nss",
        submenu: []
    },
    {
        title: "Visitors Note",
        link: "/visitors-note",
        submenu: []
    },
    {
        title: "Users",
        link: "/users",
        submenu: []
    },
    {
        title: "Logs",
        link: "/logs",
        submenu: []
    },

]

class PrimarySearchAppBar extends React.Component {

    constructor(props) {
        super(props);
        this.Auth = new AuthService();
        let user1 = JSON.parse(localStorage.getItem('user'));
        if(user1 != null){
            let user = user1.user;
            this.state = {
                apikey: user.apikey,
                username: user.email,
                role: user.role
            }
        }

        
    }

    onLogout = () => {
        this.Auth.logout();
        this.props.history.replace('/login');
    }
    render() {
        return (
            <Navbar bg="dark" variant="dark" expand="lg">
                <Navbar.Brand href="/">Dispur College</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav >
                        {this.state.role == 'SUPERADMIN' && menuListObject.map((el, index) =>
                            <Nav.Link key={index} onClick={() => this.props.history.push(el.link)}>{el.title}</Nav.Link>
                        )}

                        <Nav.Link onClick={this.onLogout}>Logout</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default withStyles(styles)(PrimarySearchAppBar);
