import React, { Component } from 'react';
import { Typography, Grid, Table, TableHead, TableCell, TableRow, TableBody, Chip } from '@material-ui/core';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import { Button, FormControl, Card } from 'react-bootstrap';
import { PostData, GetData, DeleteData, PutData } from '../../api/service';
import Snack from '../Snackbar/Snack';
import FileUpload from '../AddNew/FileUpload';
import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

export const PHOTOS_LIST_NEW = [
    {
        title: "Banner Images",
        value: "BANNER_IMAGES"
    },
    {
        title: "Student Progression & Job Placements (Achievements)",
        value: "HIGHLIGHTS"
    },
    {
        title: "Research Highlights",
        value: "RESEARCH_HIGHLIGHTS"
    },
    {
        title: "NCC Highlights",
        value: "NCC_HIGHLIGHTS"
    },
    {
        title: "NSS Highlights",
        value: "NSS_HIGHLIGHTS"
    },
    // {
    //     title: "Facilities",
    //     value: "FACILITY_HIGHLIGHTS"
    // }
]

class PhotosOther extends Component {
    state = {
        id: '',
        caption: '',
        dept: this.props.dept,
        type: '',
        date: '',
        index1: '',
        upload_info: [],
        isDataLoaded:false,
        loadFile:true,
    }

    conditions = {
        dept: this.props.dept,
        type: "",
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    __getGalleryData = () => {
        let type = 'COLLEGE';
        if(this.props.type == ''){
            type = 'COLLEGE';
        }else{
            type = this.props.type
        }

        let dept = '';
        if(this.props.dept == ''){
            dept = '';
        }else{
            type = this.props.dept
        }
        PostData(`/getgallery`,this.conditions)
            .then((resp) => {
                this.setState({
                    data: resp,
                    isDataLoaded: true
                })
            console.log(resp);
            })
    }

    
    componentDidMount() {
        this.__getGalleryData();

    }


    onSubmit = (e) => {
        e.preventDefault();

        let d = {
            id: this.state.id,
            caption: this.state.caption,
            dept: this.props.dept,
            type: this.state.type,
            index1: this.state.index1,
            date: this.state.date,
            upload_info: this.state.upload_info,
        }

        if (this.state.edit) {
            PutData(`/admin/${this.state.id}/editgallery`, d)
                .then((resp) => {
                    //console.log(resp)
                    this.setState({
                        message: resp,
                        open: true
                    }, () => this.__getGalleryData())
                    this.cancelEdit();
                })
        } else {
            PostData(`/admin/addgallery`, d)
                .then((resp) => {
                    console.log(resp)
                    this.setState({
                        message: resp,
                        open: true
                    }, () => this.__getGalleryData())
                    this.cancelEdit();
                })
        }


    }

    onDeleteClick = (el) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: this.onDeleteConfirm.bind(this, el.id)
                },
                {
                    label: 'No'
                }
            ]
        })
    }

    onDeleteConfirm = (id) => {
        DeleteData(`/${this.props.apikey}/${id}/deletegallery`)
            .then((resp) => {
                this.setState({
                    message: resp,
                    open: true
                }, () => this.__getDeptData())
            })
    }

    onEditClick = (el) => {
        this.setState({
            ...el,
            edit: true,
            loadFile: false
        }, () => {
            this.setState({
                loadFile: true
            })
        })
        console.log(this.state);
    }

    cancelEdit = () => {
        this.setState({
            id: '',
            title: '',
            description: '',
            date: '',
            index1: '',
            opening_date: '',
            closing_date: '',
            upload_info: [],
            edit: false,
            loadFile: false
        }, () => {
            this.setState({
                loadFile: true
            })
        })
    }


    getTitleOfType = (type) => {
        let ff = PHOTOS_LIST_NEW.find(el => el.value == type)
        if(ff){
            return ff.title;
        }
    }


    render() {
        return (
            <div>

                {this.state.open ? (
                    <Snack
                        open={this.state.open}
                        message={this.state.message}
                        handleClose={() => {
                            this.setState({
                                open: false,
                                message: ''
                            })
                        }}
                    />
                ) : null}

                <form onSubmit={this.onSubmit}>
                    <Typography
                        variant="h6"
                        gutterBottom
                    >
                        Website Photos
                    </Typography>

                    <Card>
                        <Card.Body>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4} md={4} lg={4}>
                                    <label>Select Type of Photo You want to change or update</label>
                                    <select className='form-control' name="type" value={this.state.type} onChange={this.onChange}>
                                        <option>--select--</option>
                                        {this.props.photos_list_new.map((el,index) => <option key={index} value={el.value}>{el.title}</option>)}
                                    </select>
                                </Grid>

                                <Grid item xs={12} sm={2} md={2} lg={2}>
                                    <label>Index</label>
                                    <FormControl
                                        as="input"
                                        name="index1"
                                        required
                                        onChange={this.onChange}
                                        value={this.state.index1}
                                        placeholder="Index"
                                    />
                                
                               
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <label>Caption</label>
                                    <FormControl
                                        as="input"
                                        name="caption"
                                        required
                                        onChange={this.onChange}
                                        value={this.state.caption}
                                        placeholder="Caption Of the Image"
                                    />
                                </Grid>
                               
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Upload Picture/ File (Single)</label>
                                    <div style={{color: "red", marginBottom: 10}}>
                                        Banner Image Size must be : 950px x 650px<br />
                                        
                                    </div>
                                    {this.state.loadFile ? (<FileUpload
                                        dp={this.state.upload_info}
                                        type={this.state.type}
                                        setDp={this.handleChange.bind(this, 'upload_info')}
                                    />) : null}
                                </Grid>

                                
                            </Grid>



                            {this.state.edit ? (
                                <div align="right">
                                    <Button
                                        size="sm"
                                        variant="danger"
                                        type="submit"
                                    >
                                        Update
                                    </Button>
                                    &nbsp;
                                    <Button
                                        size="sm"
                                        variant="danger"
                                        type="button"
                                        onClick={this.cancelEdit}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            ) : (<div align="right">
                                <Button
                                    size="sm"
                                    variant="info"
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </div>)}

                        </Card.Body>

                    </Card>

                    <br />


                    <Card>

                        <Card.Body>
                            {this.state.isDataLoaded ? (
                                <div>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>#</TableCell>
                                                <TableCell width="30%">Image Type</TableCell>
                                                
                                                <TableCell width="30%">Index</TableCell>
                                                <TableCell width="30%">Caption</TableCell>
                                                <TableCell wodth='20%'>Uploaded On</TableCell>
                                                <TableCell wodth='20%'>Images</TableCell>
                                                <TableCell>Edit</TableCell>
                                                <TableCell>Delete</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.data.filter((el) => this.props.photos_list.includes(el.type)).map((el, index) =>

                                                <TableRow key={index}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>{this.getTitleOfType(el.type)}</TableCell>
                                                    <TableCell>{el.index1}</TableCell>
                                                    <TableCell>{el.caption}</TableCell>
                                                    <TableCell>{el.time_stamp}</TableCell>
                                                    <TableCell>
                                                        {Array.isArray(el.upload_info) && el.upload_info.map((el1, index1) =>
                                                            <Chip
                                                                key={index1}
                                                                label={el1.path}
                                                            />
                                                        )}
                                                    </TableCell>                                                    
                                                    <TableCell><Edit onClick={this.onEditClick.bind(this, el)} /></TableCell>
                                                    <TableCell><Delete onClick={this.onDeleteClick.bind(this, el)} /></TableCell>
                                                </TableRow>

                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                            ) : <Typography>
                                    Loading . . .
                            </Typography>}
                        </Card.Body>

                    </Card>

                </form>
            </div>
        );
    }
}


export default PhotosOther;