import { Paper, Tab, Tabs, Typography } from '@material-ui/core'
import React from 'react'
import IQACForm from './IQACComponent/IQACForm'
import Stretegies from './IQACComponent/Stretegies'

export default function IqacLanding({ apikey, username, role, history }) {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div>
      <Typography gutterBottom variant="h6">
        IQAC
      </Typography>

      <Paper square>
        <IQACForm apikey={apikey} />
      </Paper>

      {/* <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label=""
        > 
          <Tab label="Stretegies" />
          <Tab label="Functions" />
          <Tab label="IQAC Teams" />
          <Tab label="SSR" />
          <Tab label="AQAR" />
          <Tab label="Action Taken Report" />
          <Tab label="Meeting Minutes" />
          <Tab label="Feedback Form" />
          <Tab label="Feedback Analysis" />
          <Tab label="Student Satisfactory Survey" />
          <Tab label="Event/News" />
        </Tabs>
      </Paper>

      <div style={{ paddingTop: 10 }}>
        {value == 0 && <IQACForm type="IQAC_STRATEGIES" title="IQAC Stretegies" apikey={apikey} />}
        {value == 1 && <IQACForm type="IQAC_FUNCTIONS" title="Functions" apikey={apikey} />}
        {value == 2 && <IQACForm type="IQAC_TEAMS" title="IQAC Teams" apikey={apikey} />}
        {value == 3 && <IQACForm type="IQAC_SSR" title="Self Study Report" apikey={apikey} />}
        {value == 4 && <IQACForm type="IQAC_AQAR" title="AQAR" apikey={apikey} />}
        {value == 5 && <IQACForm type="IQAC_ATR" title="Action Taken Report" apikey={apikey} />}
        {value == 6 && <IQACForm type="IQAC_MINUTES" title="Meeting Minutes" apikey={apikey} />}
        {value == 7 && <IQACForm type="IQAC_FEEDBACK_FORM" title="Feedback Form" apikey={apikey} />}
        {value == 8 && <IQACForm type="IQAC_FEEDBACK_ANALYSIS" title="Feedback Analysis" apikey={apikey} />}
        {value == 9 && <IQACForm type="IQAC_SSR" title="Student Satisfactory Survey" apikey={apikey} />}
        {value == 10 && <IQACForm type="IQAC_EVENT" title="Event/News" apikey={apikey} />}
      </div> */}

    </div>
  )
}


