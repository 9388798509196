import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import { Tabs, Tab } from "react-bootstrap";
import { GetData } from "../../api/service";
import DepartmentGlance from "./DepartmentGlance";
import DepartmentContact from "./DepartmentContact";
import DepartmentOthers from "./DepartmentGallery";
import DepartmentCourses from "./DepartmentCourses";
import DepartmentSyllabus from "./DepartmentSyllabus";
import DepartmentFaculty from "./DepartmentFaculty";
import DepartmentEvent from "./DepartmentEvent";
import DepartmentAchievements from "./DepartmentAchievements";
import DepartmentAlumni from "./DepartmentAlumni";
import DepartmentHomePage from "./DepartmentHomePage";
import DepartmentActivity from "./DepartmentActivity";
import DepartmentResearch from "./DepartmentResearch";
import DepartmentGallery from "./DepartmentGallery";
import history from "../../history";
import Gallery from "../Gallery/Gallery";
import ChangePassword from "../Settings/ChangePassword";
import AuthService from "../../auth_components/js/AuthService";
import DepartmentWallMagazine from "./DepartmentWallMagazine";
import DepartmentSeminar from "./DepartmentSeminar";

const smallTab = [
  {
    title: "Home",
    active: 0,
  },
  {
    title: "Glance",
    active: 1,
  },
  {
    title: "Courses",
    active: 2,
  },
  {
    title: "Faculty/Employee",
    active: 3,
  },
  {
    title: "Syllabus",
    active: 4,
  },
  {
    title: "Journal",
    active: 5,
  },
  {
    title: "Events",
    active: 6,
  },
  {
    title: "Alumni",
    active: 7,
  },
  {
    title: "Achievements",
    active: 8,
  },
  {
    title: "Gallery",
    active: 9,
  },
  {
    title: "Contact",
    active: 10,
  },
  {
    title: "Activity",
    active: 11,
  },
  {
    title: "Research",
    active: 12,
  },
  {
    title: "Change Password",
    active: 13,
  },
  {
    title: "Wall-magazine",
    active: 14,
  },
  {
    title: "Seminar/Conference/Workshop/Webinar",
    active: 15,
  },
];

const officeTab = [
  {
    title: "Home",
    active: 0,
  },

  {
    title: "Employee",
    active: 3,
  },
];

class DepartmentHomeLanding extends Component {
  state = {
    active: 0,
    dept_code: "",
    data: {},
    isDeptLoaded: false,
  };

  Auth = new AuthService();

  __getDeptData = (dept_code) => {
    GetData(`/admin/${dept_code}/getsingledeptdata`).then((resp) => {
      //console.log(resp)
      if (resp != false) {
        this.setState({
          data: resp,
          active: dept_code == "OFF" ? 7 : 0,
          isDeptLoaded: true,
        });
      } else {
        this.setState({
          data: {},
          active: dept_code == "OFF" ? 7 : 0,

          isDeptLoaded: true,
        });
      }
    });
  };

  componentDidMount() {
    let dept_code = this.props.match.params.dept_code;
    this.setState(
      {
        dept_code: dept_code,
      },
      () => {
        this.__getDeptData(this.state.dept_code);
      }
    );
  }

  render() {
    return (
      <div>
        <Typography variant="h6">
          {this.state.data.dept_name} / {this.state.data.dept_name_as}
        </Typography>
        <Typography variant="subtitle2">
          Code: {this.state.dept_code}
        </Typography>

        <br />

        {this.state.isDeptLoaded &&
        this.state.dept_code != "OFF" &&
        this.state.dept_code != "LIB" &&
        this.state.dept_code != "BEARER" ? (
          <Tabs
            activeKey={this.state.active}
            onSelect={(k) => this.setState({ active: k })}
          >
            {smallTab.map((el, index) => (
              <Tab key={index} eventKey={index} title={el.title}>
                <br />
              </Tab>
            ))}
          </Tabs>
        ) : null}

        {this.state.isDeptLoaded &&
        (this.state.dept_code == "OFF" ||
          this.state.dept_code == "LIB" ||
          this.state.dept_code == "BEARER") ? (
          <Tabs
            activeKey={this.state.active}
            onSelect={(k) => this.setState({ active: k })}
          >
            {officeTab.map((el, index) => (
              <Tab key={index} eventKey={el.active} title={el.title}>
                <br />
              </Tab>
            ))}
          </Tabs>
        ) : null}

        {this.state.active == 0 ? (
          <DepartmentHomePage {...this.state.data} />
        ) : null}

        {this.state.active == 1 ? (
          <DepartmentGlance {...this.state.data} />
        ) : null}

        {this.state.active == 2 ? (
          <DepartmentCourses {...this.state.data} />
        ) : null}

        {this.state.active == 3 ? (
          <DepartmentFaculty {...this.state.data} />
        ) : null}

        {this.state.active == 4 ? (
          <DepartmentSyllabus {...this.state.data} type="SYLLABUS" />
        ) : null}

        {this.state.active == 5 ? (
          <DepartmentSyllabus {...this.state.data} type="JOURNAL" />
        ) : null}

        {this.state.active == 6 ? (
          <DepartmentEvent {...this.state.data} />
        ) : null}

        {this.state.active == 7 ? (
          <DepartmentAlumni {...this.state.data} />
        ) : null}

        {this.state.active == 8 ? (
          <DepartmentAchievements {...this.state.data} type="ACHIEVEMENTS" />
        ) : null}

        {this.state.active == 9 ? (
          <Gallery
            // type="COLLEGE"
            type="DEPARTMENT"
            // dept="DISPUR"
            dept={this.state.data.dept_code}
            history={this.props.history}
            apikey={this.state.apikey}
            username={this.state.username}
          />
        ) : null}

        {this.state.active == 10 ? (
          <DepartmentContact {...this.state.data} />
        ) : null}

        {this.state.active == 11 ? (
          <DepartmentActivity {...this.state.data} />
        ) : null}

        {this.state.active == 12 ? (
          <DepartmentResearch {...this.state.data} />
        ) : null}

        {this.state.active == 13 ? (
          <ChangePassword
            {...this.state.data}
            username={new AuthService().getUserName()}
          />
        ) : null}

        {this.state.active == 14 ? (
          <DepartmentWallMagazine {...this.state.data} type="WALL_MAGAZINE" />
        ) : null}

        {this.state.active == 15 ? (
          <DepartmentSeminar {...this.state.data} type="SEMINAR" />
        ) : null}
      </div>
    );
  }
}

export default DepartmentHomeLanding;
